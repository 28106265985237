import { Text, useMantineTheme } from "@mantine/core"
import Link from "next/link"
import { useRouter } from "next/router"
import { CSSProperties } from "react"
import { ExternalLink } from "tabler-icons-react"

export const InlinePageLink = ({ children, href, newTab, style, color, }: { children: string, href: string, newTab?: boolean, style?: CSSProperties, color?: string }) => {
    const router = useRouter()
    const theme = useMantineTheme()

    return <Text fz={"inherit"} fw={"inherit"} span component={Link} href={href} target={newTab ? "_blank" : undefined}
        // onClick={() => newTab ? window.open(href) : router.push(href)} 
        className="cp" style={{ borderBottom: `1px solid ${color || theme.colors.blue[6]}`, color: color || theme.colors.blue[6], ...style }}>
        {children}
        {newTab
            ? <ExternalLink size={15} color={color || theme.colors.blue[6]} style={{ right: 0, marginLeft: 5 }} />
            : null
        }

    </Text>
}