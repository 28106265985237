import { AgencySelectModalContext } from '@/utils/context/AgencySelectModal.context'
import { UserContext } from '@/utils/context/User.context'
import { ActionIcon, Button, Collapse, Modal, Paper, Select, Text } from '@mantine/core'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { InfoCircle, Plus } from 'tabler-icons-react'

const SelectModal = ({ close, callback }: { close: () => void, callback: ((aid: string) => void) | null }) => {
    const router = useRouter()
    const user = useContext(UserContext)
    const [selected, setSelected] = useState<string | null | undefined>(user?.agencies[0]?.id)
    const [help, setHelp] = useState(false)
    return (
        <>
            <Paper p="md">
                <Text fw={600}>Select an Agency</Text>
                <Text c="dimmed" fz="sm">Choose an agency from the dropdown to continue</Text>
                <div className='flex aic' style={{ gap: 5, marginTop: 10 }}>
                    <Select style={{ flex: 1 }} value={selected} onChange={setSelected} placeholder='Select an agency' data={user?.agencies.map((a) => ({ value: a.id, label: a.name }))} />
                    <ActionIcon h={36} radius={10} w={36} variant={"default"} onClick={() => router.push("/auth/signup/create")}><Plus size={18} /></ActionIcon>
                </div>
                {/* <Button mt={5} size={"compact-sm"} leftSection={<Plus size={18} />} variant={"subtle"}>Create New</Button> */}
                <div className='flex aic jcsb' style={{ gap: 10, marginTop: 10 }}>
                    <Button disabled={!selected} onClick={() => {
                        if (!selected) return;
                        window.localStorage.setItem("agency", selected)
                        if (callback) {
                            callback(selected)
                        }
                        close()
                    }}>Continue</Button>
                    <Button onClick={() => setHelp(!help)} variant={"subtle"} size="compact-xs">{help ? "Hide " : ""}Help</Button>
                </div>
            </Paper>
            <Collapse in={help}>
                <Paper p="md" bg="gray.0">
                    <div className='flex' style={{ gap: 8 }}>
                        <InfoCircle style={{ minWidth: 14 }} size={14} />
                        <div>
                            <Text lh={1} fw={600} fz="sm">Why does this happen?</Text>
                            <Text mt={3} c="dimmed" fz="xs" >If you have multiple agencies attached to your account, sometimes your browser loses track of which agency you're currently logged in to.</Text>
                        </div>
                    </div>
                </Paper>
            </Collapse>
        </>
    )
}
const defaultState = {
    open: false,
    callback: () => void 0
}
export default function AgencySelectModalProvider({ children }: PropsWithChildren) {
    const [open, setOpen] = useState<{
        callback: ((aid: string) => void),
        open: boolean
    }>({
        open: false,
        callback: () => void 0
    })

    return (
        <>
            <Modal
                radius={"md"}
                opened={open.open}
                onClose={() => setOpen({ ...defaultState })}
                withCloseButton={false}
                centered
                closeOnEscape={false}
                closeOnClickOutside={false}
                styles={{ body: { padding: 0 } }}
            >
                <SelectModal close={() => setOpen({ ...defaultState })} callback={open.callback} />
            </Modal>
            <AgencySelectModalContext.Provider value={{
                query: (cb) => setOpen({
                    open: true,
                    callback: cb
                })
            }}>
                {children}
            </AgencySelectModalContext.Provider>
        </>
    )
}
