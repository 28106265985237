import { AccountTypes, Agency, AnyService, LinkedAccount } from "../../../../types/global";

type OperationCallback = (acc: LinkedAccount, err: undefined) => void

export const getLinkedAccountByID = (agency: Agency, platform: AccountTypes, internalAccountID: string, cb: OperationCallback) => {
    const act = agency.linkedAccounts[platform]?.find((a) => a.internalID === internalAccountID)

    // @ts-ignorei
    if (!act) return cb(null, `That ${platform} account does not exist or has been unlinked from AgencyAccess`);
    // @ts-ignore
    cb(act)
}

export const getLinkedAccountByService = (agency: Agency, platform: AccountTypes, service: AnyService, cb: OperationCallback) => {
    const act = agency.linkedAccounts[platform]?.find((a) => a.services.includes(service))
    // @ts-ignore
    if (!act) return cb(null, `Please select a ${platform} account for ${service}.`);
    // @ts-ignore
    cb(act)
}