import lang from '@/../public/translations/intake.json'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { Box, Checkbox, MantineSize, Radio, Text, TextInput, Textarea } from '@mantine/core'
import { useContext } from 'react'
import { ClientLanguage, IntakeQuestion } from '../../../../types/global'


export const IntakeFormQA = ({ question, i, answer, setAnswer, langOverwrite, size, disableNumbers, disableSpacing }: { question: IntakeQuestion, i: number, answer: string | string[], setAnswer: (a: string | string[]) => void, langOverwrite?: ClientLanguage, size?: MantineSize, disableNumbers?: boolean, disableSpacing?: boolean }) => {
    const invite = useContext(InviteClientContext)
    const l = invite?.lang || langOverwrite || "English"
    return (
        <Box>
            <Box className='flex' style={{ gap: 5 }}>
                {!disableNumbers
                    ? <Text span fw={500} c="dimmed">{i + 1}.</Text>
                    : null
                }

                <Box>
                    <Text fw={500}>{question.title} <Text span c={"red"} fw={600}>{question.required ? "*" : ""}</Text></Text>
                    {question.description
                        ? <Text fz="xs" c="dimmed">{question.description}</Text>
                        : null
                    }

                </Box>
            </Box>

            {question.type === "Input"
                ? <TextInput size={size} mt={disableSpacing ? 0 : 5} placeholder={question.placeholder || lang.placeholders.input[l]} onChange={(e) => setAnswer(e.target.value)} />
                : null
            }

            {question.type === "Textbox"
                ? <Textarea size={size} radius={10} minRows={2} autosize mt={disableSpacing ? 0 : 5} placeholder={question.placeholder || lang.placeholders.textbox[l]} onChange={(e) => setAnswer(e.target.value)} />
                : null
            }

            {question.type === "Multi-select"
                ? <Box ml={disableSpacing ? 0 : 10} className='flex fdc' style={{ gap: 10 }} mt={5}>
                    {question.options.map((o) => <Checkbox
                        label={o}
                        checked={answer?.includes(o)}
                        onChange={(e) => {
                            if (e.target.checked) {

                                setAnswer(answer ? [...answer, o] : [o])
                            } else {
                                const newAnswer = [...answer]
                                newAnswer.splice(answer.indexOf(o), 1)
                                setAnswer(newAnswer)
                            }
                        }}
                    />)}
                </Box>
                : null
            }

            {question.type === "Option"
                ? <Box ml={disableSpacing ? 0 : 10} className='flex fdc' style={{ gap: 10 }} mt={5}>
                    {question.options.map((o) => <Radio
                        label={o}
                        checked={answer === o}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setAnswer(o)
                            } else {
                                setAnswer("")
                            }
                        }}
                    />)}
                </Box>
                : null
            }

        </Box>
    )
}