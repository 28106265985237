import { Logo } from '@/components/Logo'
import { NavbarRoute } from '@/components/Navbar'
import AdminProvider from '@/providers/Admin.provider'
import UserProvider from '@/providers/User.provider'
import { UserContext } from '@/utils/context/User.context'
import { adminDashboardRoutes } from '@/static/routes'
import { ActionIcon, Avatar, Badge, Box, Text, TextInput } from '@mantine/core'
import Link from 'next/link'
import React, { PropsWithChildren, useContext } from 'react'
import { Logout } from 'tabler-icons-react'

const Header = () => {
    const user = useContext(UserContext)
    return (
        <Box component={"header"} h={80} px={25} className="flex aic jcsb" style={{ borderBottom: "1px solid #e9ecef" }}>
            <Box className='flex aic' style={{ gap: 10 }}>
                <Logo width={185} href='/admin' />
                <Badge variant={"light"} color={"blue"} size="md">Internal Tools</Badge>
            </Box>
            <Box className='flex aic' style={{ gap: 5 }}>
                <Avatar style={{ borderRadius: 10 }} src={user?.pfp}>{user?.name.substring(0, 1)}</Avatar>
                <Box>
                    <Text lh={1.4} fz="sm">{user?.name}</Text>
                    <Text lh={1.4} c="dimmed" fz="xs">{user?.email}</Text>
                </Box>
                <Link href={"/logout"}>
                    <ActionIcon ml={10} size={"sm"} variant={"subtle"} color="gray">
                        <Logout size={18} />
                    </ActionIcon>
                </Link>
            </Box>
        </Box>
    )
}

const Navbar = () => {
    return (
        <Box component={"nav"} w={240} className="flex fdc jcsb" p="sm" style={{ borderRight: "1px solid #e9ecef", gap: 5 }}>
            <div className='flex fdc' style={{ gap: 5 }}>
                {adminDashboardRoutes.map(r => {
                    return (
                        <NavbarRoute base='/admin' r={r} />
                    )
                })}
            </div>
        </Box>
    )
}

export default function AdminLayout({ children }: PropsWithChildren) {
    return (
        <UserProvider>
            <AdminProvider>
                <Header />
                <div className='flex' style={{ height: "calc(100vh - 80px)" }}>
                    <Navbar />
                    <div style={{ overflowY: "auto", flex: 1 }}>
                        {children}
                    </div>
                </div>
            </AdminProvider>
        </UserProvider>
    )
}
