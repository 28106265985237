import { Avatar, Badge, Box, Rating, Text, useMantineTheme } from '@mantine/core'
import { useElementSize, useMediaQuery } from '@mantine/hooks'
import dynamic from 'next/dynamic'
import React, { useEffect, useState } from 'react'

// @ts-ignore
const MotionComponent = dynamic(() => import('framer-motion').then(module => module.motion.div))
// @ts-ignore
const AnimatePresenceComponent = dynamic(() => import('framer-motion').then(module => module.AnimatePresence));

const ReviewsCycle = () => {
    const reviews = [
        {
            review: "AgencyAccess has revolutionized the way we onboard clients. The customization options are fantastic, and the security measures put our minds at ease. It's a must-have tool for agencies.",
            stars: 5,
            person: {
                name: "Bernt Muurling",
                job: "Co-founder at Taskforce Agency",
                icon: "/images/landing_page/bernt.jpeg"
            }
        },
        {
            review: "Using AgencyAccess has significantly improved our efficiency. The link preview feature is a game-changer, and the fact that they don't access our data shows their commitment to privacy.",
            stars: 5,
            person: {
                name: "Ruben Runneboom",
                job: "Co-founder at Taskforce Agency",
                icon: "/images/landing_page/ruben.jpeg"
            }
        },
        // {
        //     review: "AgencyAccess has been a productivity booster for our agency. The branding options help us stand out, and the ease of sending links is fantastic. It's become an essential part of our workflow.",
        //     stars: 5,
        //     person: {
        //         name: "Person Name",
        //         job: "Head of Marketing",
        //         icon: ""
        //     }
        // }

    ] as Array<{
        review: string,
        stars: number,
        person: {
            name: string,
            icon: string,
            job: string
        }
    }>
    const [activeReview, setActiveReview] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (reviews[activeReview + 1]) {
                setActiveReview(activeReview + 1)
            } else {
                setActiveReview(0)
            }
        }, 5000)
    }, [activeReview])
    if (!AnimatePresenceComponent || !MotionComponent) {
        return null
    }
    return (
        <div style={{ marginBottom: 30 }}>
            <AnimatePresenceComponent mode="wait">
                {/* @ts-ignore */}
                <MotionComponent
                    key={activeReview}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <Box style={{ backdropFilter: "blur(20px)", borderRadius: 15, background: "rgba(255,255,255,.1)" }} p="lg">
                        <Rating value={5} readOnly />
                        <Text mt={5} fz="lg" fw={500} c="#fff">
                            <Text c="#fff" fz={"lg"} style={{ textShadow: "0px 0px 0px #fff" }} fw={900} span>“</Text>
                            {reviews[activeReview].review}
                            <Text c="#fff" fz={"lg"} style={{ textShadow: "0px 0px 0px #fff" }} fw={900} span>”</Text>
                        </Text>
                        <div style={{ marginTop: 10, gap: 10 }} className="flex" >
                            <Avatar variant={"filled"} radius={100} src={reviews[activeReview].person.icon} />
                            <div>
                                <Text fw={600} c="#fff">{reviews[activeReview].person.name}</Text>
                                <Text c="rgba(255,255,255,.5)" fz={"sm"}>{reviews[activeReview].person.job}</Text>
                            </div>
                        </div>
                    </Box>
                </MotionComponent>
            </AnimatePresenceComponent>

        </div>
    );
}

export default function AuthLayout({ children, title, subtitle, badge, showReviews }: { children: React.ReactNode, title: string, subtitle: string, badge: string, showReviews?: boolean }) {
    const vertical = useMediaQuery("(max-width: 1080px)")
    const mobile = useMediaQuery("(max-width: 400px)")
    const theme = useMantineTheme()
    const { ref, height } = useElementSize();
    const { ref: containerRef } = useElementSize()
    useEffect(() => {
        try {
            // @ts-ignore
            var gradient = new Gradient();
            gradient.initGradient("#canvas");
        } catch (err) {
            void 0
        }
    }, [])
    return (
        <>
            <div className={`flex aic ${vertical ? "fdc" : ""}`} ref={containerRef} style={{ height: !vertical ? "100vh" : "fit-content", overflow: "hidden", position: "relative", minHeight: height + 48, width: '100vw', background: theme.colors.dark[6], }}>
                <canvas id="canvas" style={{ left: 0, top: 0 }} />
                <Box p={vertical ? "md" : "xl"} className={`flex jcc fdc ${showReviews ? "jcsb" : ""}`} style={{ zIndex: 300, flex: 1, position: "relative", overflow: "hidden", height: "fit-content", minHeight: 200, boxSizing: "border-box", width: vertical ? "100%" : undefined, maxWidth: !vertical ? "50%" : undefined }}>
                    {showReviews && !vertical
                        ? <ReviewsCycle />
                        : null
                    }
                    <div>
                        <Badge size={"md"} w={"fit-content"} variant="light" color="white">{badge}</Badge>
                        <Text color={"#fff"} fw={800} fz={mobile ? 32 : 40}>{title}</Text>
                        <Text color={"#fff"} fw={500} fz={mobile ? 15 : 20} >{subtitle}</Text>
                    </div>
                </Box>
                {/* @ts-ignore */}
                <Box bg={"#fff"} style={{ flex: 1, boxShadow: "0px 0px 10px rgba(0,0,0,.5)", width: "100%", zIndex: 300, height: vertical ? undefined : "100%" }} pb={vertical ? 50 : 0} className="flex aic jcc">
                    <Box component={"div"} ref={ref} className='flex aic jcc fdc' style={{ height: "fit-content", minHeight: "fit-content", background: "#fff", width: "100%" }} p={vertical ? "md" : "xl"}>
                        {children}
                    </Box>
                </Box>
            </div>
        </>
    )
}
