import { doc, getDoc, onSnapshot } from "firebase/firestore"
import { useState } from "react"
import { AccountTypes, AnyService, Invite } from "../../../../types/global"
import { firestore } from "../firebase"

export const getGrantedStatus = (invite: Invite, accountType: AccountTypes) => {
    const unfulfilledAccountsArray = (Object.keys(invite.requestedAccounts[accountType]) as Array<AnyService>).filter((acc) => !invite.requestedAccounts[accountType][acc].granted)
    return !unfulfilledAccountsArray.length
}

export const getInviteGrantedStatus = (invite?: Invite) => {
    if (!invite) return false
    const unfulfilledAccountsArray = Object.keys(invite.requestedAccounts).filter((reqAcc: unknown) => {
        return !!(((Object.keys(invite.requestedAccounts[reqAcc as AccountTypes]) as Array<AnyService>).filter((acc) => !invite.requestedAccounts[reqAcc as AccountTypes][acc].granted)).length)
    })
    return !unfulfilledAccountsArray.length
}

export const useInviteGrantStatus = (inviteID?: string, inviteData?: Invite) => {
    const [granted, setGranted] = useState(getInviteGrantedStatus(inviteData))
    if (!inviteID) return false

    onSnapshot(doc(firestore, "invites", inviteID), (snap) => {
        if (!snap.exists()) return
        setGranted(getInviteGrantedStatus(snap.data() as Invite))
    })

    return granted
}