import {
    Number1, Number2, Number3, Number4, Number5, Number6, Number7, Number8, Number9,
    CircleNumber1, CircleNumber2, CircleNumber3, CircleNumber4, CircleNumber5, CircleNumber6, CircleNumber7, CircleNumber8, CircleNumber9
} from "tabler-icons-react"

export const numberIcons = [
    <Number1 />,
    <Number2 />,
    <Number3 />,
    <Number4 />,
    <Number5 />,
    <Number6 />,
    <Number7 />,
    <Number8 />,
    <Number9 />
]

export const circleNumberIcons = [
    <CircleNumber1 />,
    <CircleNumber2 />,
    <CircleNumber3 />,
    <CircleNumber4 />,
    <CircleNumber5 />,
    <CircleNumber6 />,
    <CircleNumber7 />,
    <CircleNumber8 />,
    <CircleNumber9 />
]