import { ActionIcon, Box, CopyButton, Divider, Paper, Text, Tooltip } from '@mantine/core'
import React from 'react'
import { Check, Copy } from 'tabler-icons-react'

export default function CopyBox({ value, mt, label }: { value: string, mt?: number, label?: string }) {
    return (
        <Box mt={mt || 0}>
            {label
                ? <Paper withBorder px="xs" bg={"gray.0"} py={5} radius={5} style={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                }}>
                    <Text lh={1} c={"rgba(0,0,0,.7)"} fw={600} fz="xs">{label}</Text>
                </Paper>
                : null
            }
            <Paper w={"100%"} radius={label ? 5 : 10} px="xs" className='flex aic jcsb' withBorder p={0} style={{
                padding: "0px 10px",
                width: "fit-content",
                overflow: "hidden",
                borderTop: label ? "none" : undefined,
                borderTopLeftRadius: label ? 0 : 10,
                borderTopRightRadius: label ? 0 : 10
            }}>
                <div style={{ padding: "5px 0", flex: 1, maxWidth: "90%" }}>
                    <Text mr={10} maw={"100%"} lineClamp={1} style={{ wordBreak: "break-all" }} fz={"sm"}>{value}</Text>
                </div>
                <Divider orientation="vertical" />
                <div style={{ paddingLeft: 10 }} className="flex aic jcc">
                    <CopyButton value={value} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copied' : 'Copy'} style={{ zIndex: 1000000 }} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : 'gray'} style={{ zIndex: 1000000 }} variant="subtle" onClick={copy}>
                                    {copied ? (
                                        <Check style={{ width: 16 }} />
                                    ) : (
                                        <Copy style={{ width: 16 }} />
                                    )}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                </div>
            </Paper>

        </Box>
    )
}
