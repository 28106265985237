import { GrantsStatus } from "@/components/invite/GrantsContainer";
import { useEffect, useState } from "react";
import { AccountTypes, Invite } from "../../../types/global";

export const useGrantStatus = (invite: Invite, account: AccountTypes, isPreview?: boolean) => {
    const [grantsStatus, setGrantsStatus] = useState<GrantsStatus>({})
    useEffect(() => {
        const newGrantsStatus = {} as GrantsStatus
        if (isPreview) return setGrantsStatus({});
        Object.keys(invite.requestedAccounts).map(ra => {
            if (ra !== account) return
            Object.keys(invite.requestedAccounts[ra as AccountTypes]).map((service) => {
                // @ts-ignore
                newGrantsStatus[service as AnyService] = {
                    // @ts-ignore
                    granted: !!invite.requestedAccounts[ra as AccountTypes][service as AnyService].granted
                }
            })
        })
        setGrantsStatus(newGrantsStatus)
    }, [invite, account])

    return { grantsStatus, setGrantsStatus }
}