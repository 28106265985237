import { ButtonProps } from "@mantine/core";
import { Theme } from "../../../../types/global";

export default (theme: Theme) => {
    console.log(theme)
    if (theme.theme === "dark" && theme.color === "dark") {
        return {
            color: "#fff",
            c: "#000"
        } as ButtonProps
    } else {
        return {
            color: theme.color
        } as ButtonProps
    }
}