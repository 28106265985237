import { AnyService } from "../../../types/global";

export const assetNouns = {
    "Google Ads": "ad account",
    "Google Ads MCC": "ad account",
    "Google Analytics": "analytics propertie",
    "Google Search Console": "web propertie",
    "Google Tag Manager": "container",
    "Google Merchant Center": "product feed",
    "Google Business Profile": "business profile",
    "YouTube Studio": "channel",
    "Google DV360": "campaign",

    "Meta Ads": "ad account",
    "Facebook Pages": "page",
    "Facebook Product Catalog": "catalog",
    "Instagram": "account",
    "Meta Pixels": "pixel",

    "Twitter Ads": "ad account",
    "Twitter Delegate Account": "account",

    "Pinterest Ads": "ad account",

    "TikTok Ads": "ad account",

    "Microsoft Ads": "ad account",

    "Amazon Ads": "ad account",

    "Snapchat Ads": "ad account",
    "Snapchat Business": "business account",

    "Shopify Store Collaborator": "store",

    "HubSpot Partner": "partner account",

    "ProfitMetrics Partner": "partner account",

    "LinkedIn Company Page": "company page",
    "LinkedIn Ads": "ad account",

    "Klaviyo Account": "account"
} as Record<AnyService, string>;