import Head from 'next/head'
import React from 'react'
import AuthLayout from './Auth.layout'
import UserProvider from '@/providers/User.provider'
import AgencyProvider from '@/providers/Agency.provider'
import { Loader } from '@mantine/core'
import CouponProvider from '@/providers/Coupon.provider'


export default function TrialLayout({ children }: { children: React.ReactElement }) {
    return (
        <>
            <Head>
                <script defer src="https://cdn.jsdelivr.net/gh/greentfrapp/pocoloco@minigl/minigl.js"></script>
            </Head>
            <UserProvider>
                <AgencyProvider
                    loadingScreen={<AuthLayout showReviews subtitle='' badge='Loading' title='Loading...' >
                        <Loader />
                    </AuthLayout>}
                >
                    <CouponProvider>
                        {children}
                    </CouponProvider>
                </AgencyProvider>
            </UserProvider>
        </>
    )
}
