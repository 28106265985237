import api from '@/utils/api'
import { UserContext } from '@/utils/context/User.context'
import { ActionIcon, Box, Button, Image, Modal, Paper, Select, Text, TextInput, useMantineColorScheme } from '@mantine/core'
import { useColorScheme } from '@mantine/hooks'
import Link from 'next/link'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowLeft, Reload } from 'tabler-icons-react'
import Error from '../Error'
import { useRouter } from 'next/router'
import { getLinkedAccountByID } from '@/utils/helpers/oauth'
import { AgencyContext } from '@/utils/context/Agency.context'
import { LinkedAccount } from '../../../../types/global'
import GuidePopup from '../GuidePopup'
import { InlinePageLink } from '../InlineLink'
import { notifications } from '@mantine/notifications'

export const BusinessPortfolioForm = ({
    close,
    accountEmail,
    inviteID,
    disableCopy,
    loading: loadingOverwite
}: { close: (newPageID?: string) => void, accountEmail: string, inviteID: string, disableCopy?: boolean, loading?: boolean }) => {
    const [name, setName] = useState("")
    const [vertical, setVertical] = useState<string | undefined | null>("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    return (
        <Box>
            {disableCopy
                ? null
                : <Paper shadow='xs' bg={"linear-gradient(45deg, var(--mantine-color-blue-0) 0%, #fff 100%)"} p="xs">
                    <Box className='flex' style={{ gap: 10 }}>
                        <Image src={`/images/logos/meta.png`} w={20} h={20} style={{ objectFit: "contain", position: "relative", top: 5 }} />
                        <Box>
                            <Text fz="sm">Using <span style={{ fontWeight: '500' }}>{accountEmail}</span></Text>
                            <Text fz="xs" c="dimmed">An Business Portfolio will be created under {accountEmail}</Text>
                        </Box>
                    </Box>
                </Paper>
            }

            <TextInput required mt={5} label="Business Name" placeholder='AgencyAccess' value={name} onChange={(e) => setName(e.target.value)} />
            {/* <Select data={pages} value={pageID} onChange={setPageID} placeholder={!pages ? "Loading..." : (!pages.length ? "No pages found" : "Select a page")} mt={4} label={"Facebook Page"} description={<Text lh={1} fz="xs" c="dimmed">Select a Facebook page to represent this business, or <Text target='_blank' lh={1} span component={Link} href={"https://www.facebook.com/pages/create/"} fz="xs" c="blue">create a new one</Text></Text>} /> */}
            {/* <Button loading={pagesLoading} onClick={() => fetchPages()} variant='subtle' color='blue' radius={5} leftSection={<Reload size={14} />} size="compact-xs">Refresh list</Button> */}
            <Select
                mt={4}
                required
                label="Industry"
                placeholder='Advertising'
                data={[{ "value": "ADVERTISING", "label": "Advertising" }, { "value": "AUTOMOTIVE", "label": "Automotive" }, { "value": "CONSUMER_PACKAGED_GOODS", "label": "Consumer Packaged Goods" }, { "value": "ECOMMERCE", "label": "E-commerce" }, { "value": "EDUCATION", "label": "Education" }, { "value": "ENERGY_AND_UTILITIES", "label": "Energy and Utilities" }, { "value": "ENTERTAINMENT_AND_MEDIA", "label": "Entertainment and Media" }, { "value": "FINANCIAL_SERVICES", "label": "Financial Services" }, { "value": "GAMING", "label": "Gaming" }, { "value": "GOVERNMENT_AND_POLITICS ,MARKETING", "label": "Government, Politics, and Marketing" }, { "value": "ORGANIZATIONS_AND_ASSOCIATIONS", "label": "Organizations and Associations" }, { "value": "PROFESSIONAL_SERVICES", "label": "Professional Services" }, { "value": "RETAIL", "label": "Retail" }, { "value": "TECHNOLOGY", "label": "Technology" }, { "value": "TELECOM", "label": "Telecom" }, { "value": "TRAVEL", "label": "Travel" }, { "value": "OTHER", "label": "Other" }]}
                value={vertical}
                onChange={setVertical}
            />
            <Button disabled={!name || !vertical} loading={loadingOverwite || loading} fullWidth mt={10} onClick={() => {
                setLoading(true)
                setError("")
                api.post<string>("/accounts/client/meta/create-business", {
                    name,
                    vertical,
                    inviteID
                }).then((res) => {
                    setLoading(false)
                    if (res.error) {
                        return setError(res.msg)
                    }
                    close(res.data) // New page id
                })
            }}>Create</Button>
            <Error mt={5}>{error}</Error>
        </Box>
    )
}

const CreateMetaBusinessPort = ({ accountEmail, inviteID, submitBusiness }: { accountEmail: string, inviteID: string, submitBusiness: (bid: string) => void }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Button mt={5} onClick={() => setOpen(true)} w={"fit-content"} size='compact-xs' color={"blue"} variant='subtle' radius={5}>No account? Create one</Button>
            <Modal
                opened={open}
                onClose={() => setOpen(false)}
                title={<Box>
                    <Text fw={500}>Create a Business Portfolio</Text>
                    <Text fz="sm" c="dimmed">Fill in the form to create a new Meta Business Portfolio</Text>
                </Box>}
                styles={{
                    root: {
                        zIndex: 2000
                    },
                    overlay: {
                        zIndex: 2000
                    },
                    body: {
                        zIndex: 2000
                    },
                    inner: {
                        zIndex: 2000

                    }
                }}
            >
                <BusinessPortfolioForm inviteID={inviteID} accountEmail={accountEmail} close={(bid) => {
                    setOpen(false)
                    if (!bid) return;
                    submitBusiness(bid)
                }} />
            </Modal>
        </>
    )
}

export const MetaBusinessSelector = ({ businesses, type, cb, inviteID, accountEmail }: { businesses: Array<{ id: string, name: string }>, type: "invite" | "account", cb: (business: { id: string, name: string }) => void, inviteID?: string, accountEmail?: string }) => {
    const { setColorScheme } = useMantineColorScheme();

    useEffect(() => {
        setColorScheme("light")
    }, [])

    const router = useRouter()
    const agency = useContext(AgencyContext)
    const [selectedBusiness, setSelectedBusiness] = useState<undefined | string | null>(undefined)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const { iaid } = router.query

    const linkedAccount: LinkedAccount = useMemo(() => {
        if (!iaid) return;
        let a: LinkedAccount | null = null;
        getLinkedAccountByID(agency, "Meta", iaid as string, (acc, err) => {
            if (err) return setError("Please select a Meta account to use")
            a = acc
        })
        return a
    }, []) as unknown as LinkedAccount

    const submitBusiness = (selectedBusiness: string) => {
        setLoading(true)
        api.post(
            `/accounts/${type === "invite" ? "client" : "agency"}/meta/set-business`,
            // `/oauth/${type === "invite" ? "client/" : ""}complete/facebook/business`,
            {
                selectedBusinessID: selectedBusiness,
                inviteID,
                internalAccountID: iaid
            }).then((res) => {
                if (res.error) {
                    setLoading(false)
                    return setError(res.msg)
                }
                // @ts-ignore
                cb(businesses.find((b) => b.id === selectedBusiness))
            })

    }

    return (
        <div style={{ position: "fixed", zIndex: 1000, top: 0, left: 0, background: "#fff", width: "100vw", height: "100vh" }} className="flex aic jcc fdc">
            <Link href={"/dashboard/accounts"}>
                <ActionIcon style={{ position: "absolute", left: 20, top: 20 }}>
                    <ArrowLeft size={20} />
                </ActionIcon>
            </Link>
            <div className='flex fdc' style={{ width: "100%", maxWidth: 450 }}>
                <Box className='flex' w="100%" style={{ gap: 15 }}>
                    <Image src={`/images/logos/meta.png`} style={{ objectFit: "contain", width: 30, height: 30 }} />
                    <Box>
                        <Text fw={600}>{type === "invite" ? "Connect to business suite" : "Meta Business Manager Settings"}</Text>
                        <Text c="dimmed" fz="sm">{type === "invite" ? "To continue, you'll need to select a business manager account" : "Manage your meta integration"}</Text>
                    </Box>
                </Box>
                {/* <Box p={5} className="flex aic jcc" style={{ borderRadius: 10, height: 45, width: 45 }} bg={"gray.0"}>
                    <Image src={`/images/logos/meta.png`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                </Box> */}
                {linkedAccount
                    ? <Paper mt={15} radius={10} withBorder p="sm">
                        <Box className='flex aic jcsb'>
                            <Box className='flex aic' style={{ gap: 10 }}>
                                <Image src={"/images/logos/meta.png"} width={20} height={20} />
                                <Text lh={1} fz="sm" >Logged in as <span style={{ fontWeight: 500 }}>{linkedAccount.email}</span></Text>
                            </Box>
                            <Link href={"/dashboard/accounts"}>
                                <Button size="compact-xs" variant="subtle">Switch</Button>
                            </Link>
                        </Box>
                    </Paper>
                    : null
                }

                <Box mt={10} className='flex fdc'>
                    <Select required label="Select a business manager account" description={type === "invite" ? "This should be the account that owns the assets you want to share" : "This account will be used to manage clients"} w="100%" placeholder="Select an Account" data={businesses?.map((b) => ({ value: b.id, label: `${b.name} (${b.id})` }))} value={selectedBusiness} onChange={setSelectedBusiness} />
                    {/* <GuidePopup position='left' steps={[
                        { title: "Create a Meta Business Portfolio", description: "Follow this guide to create a Meta Business Suite account for your business", image: "/images/popupGuides/mbs_1.png" },
                        {
                            title: "Follow these steps",
                            description: <Box mt={10} className='flex fdc' style={{ gap: 10 }}>
                                <Box>
                                    <GuidePopup.Title><span style={{ color: "var(--mantine-color-dimmed)" }}>1.</span> Go to Business Suite</GuidePopup.Title>
                                    <GuidePopup.Description>Navigate to <InlinePageLink href='https://facebook.business.com' newTab>facebook.business.com</InlinePageLink></GuidePopup.Description>
                                </Box>
                                <Box>
                                    <GuidePopup.Title><span style={{ color: "var(--mantine-color-dimmed)" }}>2.</span> Click the dropdown</GuidePopup.Title>
                                    <GuidePopup.Description>After logging in, you should see a dropdown in the top left-hand corner. Click into it, and select <span style={{ fontWeight: 700 }}>Create a business portfolio</span></GuidePopup.Description>
                                </Box>
                                <Box>
                                    <GuidePopup.Title><span style={{ color: "var(--mantine-color-dimmed)" }}>3.</span> Fill in infomation</GuidePopup.Title>
                                    <GuidePopup.Description>Fill in the business portfolio creation form using your business infomation</GuidePopup.Description>
                                </Box>
                            </Box>, image: "/images/popupGuides/mbs_2.gif"
                        },
                        { title: "🎉 Done", description: "You've successfully created a new Facebook Business Portfolio." },
                    ]}>
                        <Button w={"fit-content"} size='compact-xs' color={"blue"} variant='subtle' radius={5}>No account? Create one</Button>
                    </GuidePopup> */}
                    <CreateMetaBusinessPort inviteID={inviteID as string} accountEmail={accountEmail as string} submitBusiness={(bid) => {
                        submitBusiness(bid)
                    }} />
                    <Button loading={loading} mt={10} fullWidth disabled={!selectedBusiness} onClick={() => submitBusiness(selectedBusiness as string)}>Continue</Button>
                    <Error mt={10}>{error}</Error>
                </Box>

            </div>
        </div>
    )

}
