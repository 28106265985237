import { SettingsHeader } from '@/components/Headers'
import UserProvider from '@/providers/User.provider'
import React, { PropsWithChildren } from 'react'

export default function SettingsLayout({ children }: PropsWithChildren) {
    return (
        <UserProvider>
            <SettingsHeader />
            <div style={{ marginTop: 75 }}>
                {children}
            </div>
        </UserProvider>
    )
}
