import { useContext, useEffect, useState } from "react"
import { Client } from "../../../types/global"
import { ClientsContext } from "../utils/context/Clients.context"
import getClientFromContext from "../utils/helpers/getClientFromContext"

export default (id: string) => {
    const clients = useContext(ClientsContext)
    const [client, setClient] = useState<Client | undefined>(undefined)
    useEffect(() => {
        const clientProfile = getClientFromContext(clients, id)
        setClient(clientProfile)
    }, [clients])

    return client
}