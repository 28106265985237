import api from '@/utils/api'
import { ManualGrantsContext } from '@/utils/context/ManualGrants.context'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { AnyService } from '../../../types/global'

export default function ManualGrantsProvider({ children }: PropsWithChildren) {
    const [manualGrants, setManualGrants] = useState<Array<AnyService>>([])

    useEffect(() => {
        api.get<AnyService[]>("/public/platforms/manual-services").then((res) => {
            console.log(res)
            if (res.error) return;
            setManualGrants(res.data)
        })
    }, [])

    return (
        <ManualGrantsContext.Provider value={manualGrants}>
            {children}
        </ManualGrantsContext.Provider>
    )
}
