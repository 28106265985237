import { AccountTypes, AnyService, RequestedAccounts } from "../../../../types/global";

const getServices: (requestedAccounts: RequestedAccounts) => AnyService[] = (requestedAccounts: RequestedAccounts) => {
    const services: AnyService[] = new Array()
    Object.keys(requestedAccounts).map((acc) => {
        Object.keys(requestedAccounts[acc as AccountTypes]).map((service) => {
            if (requestedAccounts[acc as AccountTypes][service as AnyService].requested) {
                services.push(service as AnyService)
            }
        })
    })
    return services
}

export default getServices