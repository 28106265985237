import React, { useEffect, useState } from 'react'
import { UpdateNotes } from '../../../types/global'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { firestore } from '@/utils/firebase'
import { Badge, Box, Button, Divider, Modal, Paper, Text } from '@mantine/core'
import api from '@/utils/api'

const ModalContent = ({ updates, setOpen, isOverwrite }: { updates: UpdateNotes, setOpen: (o: boolean) => void, isOverwrite: boolean }) => {
    return (
        <>
            <Divider mb={10} />
            <Box mt={20} className='flex aic jcc fdc'>
                <Text mt={10} fz="xs" c="dimmed" fw={600}>NEW UPDATES</Text>
                <Text fz={24} lh={1} fw={700}>{updates?.title}</Text>
                <Box w="100%" mt={30} className='flex fdc' style={{ gap: 10 }}>
                    {updates?.updates.map((u) => (
                        <Paper shadow='xs' radius={10} w="100%" withBorder p="sm">
                            <Box className='flex aic jcsb'>
                                <Text fw={600}>{u.title}</Text>
                                <Badge color={u.type === "bug fix" ? "green" : "blue"} variant="light" radius={5}>{u.type}</Badge>
                            </Box>
                            <Text mt={8} fz="sm">{u.description}</Text>
                            {!isOverwrite
                                ? <Text fz={"sm"} c="dimmed" mt={5}>Released {new Date(updates.created).toDateString()}</Text>
                                : null
                            }

                        </Paper>
                    ))}
                </Box>
            </Box >
            <Button mt={10} fullWidth onClick={() => setOpen(false)}>Close</Button>
        </>
    )
}

export default function WhatsNewModal({ overwrite }: { overwrite?: UpdateNotes }) {
    const [open, setOpen] = useState(false)
    const [deploymentID, setDeploymentID] = useState("")
    const [updates, setUpdates] = useState<UpdateNotes | undefined>(overwrite || undefined)

    useEffect(() => {
        if (overwrite) return;
        // const deploymentID = process.env.VERCEL_DEPLOYMENT_ID
        api.get("/version/app", {}, undefined, false).then((res) => {
            const deploymentID = res.data
            if (!deploymentID) return;
            const RAW_viewedUpdates = window.localStorage.getItem("@agencyaccess_viewed_updates")
            // if (RAW_viewedUpdates && (JSON.parse(RAW_viewedUpdates) as string[]).find((u) => u === deploymentID)) return;
            getDocs(query(collection(firestore, "updateNotes"), where("created", ">", new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime()))).then((snaps) => {
                const updateNotes = snaps.docs.map((s) => ({ ...s.data() as UpdateNotes, id: s.id }))
                console.log(updateNotes)
                let viewedUpdates: string[];
                try {
                    viewedUpdates = JSON.parse(RAW_viewedUpdates as string) || []
                } catch {
                    viewedUpdates = []
                }
                console.log(viewedUpdates)
                const parsedUpdateNotes = updateNotes?.filter(({ id }) => !viewedUpdates?.includes(id))?.sort((a, b) => b.created - a.created)
                console.log(parsedUpdateNotes)
                if (parsedUpdateNotes[0]) {
                    setDeploymentID(parsedUpdateNotes[0].id)
                    setUpdates(parsedUpdateNotes[0])
                    if (!window.Cypress) {
                        setOpen(true)
                    }
                }
            })
            // getDoc(doc(firestore, "updateNotes", deploymentID)).then((snap) => {
            //     const updateNotes = snap.data() as UpdateNotes
            //     if (!snap.exists() || !updateNotes.updates.length || !updateNotes.title) return;
            //     setUpdates(snap.data() as UpdateNotes)
            //     setOpen(true)
            // })
        })
        // console.log(deploymentID)
    }, [])

    useEffect(() => {
        if (overwrite) return;
        if (open || !updates || !deploymentID) return;
        const RAW_viewedUpdates = window.localStorage.getItem("@agencyaccess_viewed_updates")
        const viewedUpdates = RAW_viewedUpdates ? JSON.parse(RAW_viewedUpdates) as string[] : []
        console.log("Pushing", deploymentID)
        viewedUpdates.push(deploymentID as string)
        window.localStorage.setItem("@agencyaccess_viewed_updates", JSON.stringify(viewedUpdates))
    }, [open, deploymentID])

    if (overwrite) {
        return (
            <ModalContent
                isOverwrite
                setOpen={() => void 0}
                updates={overwrite}
            />
        )
    }

    return (
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={<Text fw={600}>What's New</Text>}
            styles={{ header: { minHeight: 0, paddingBottom: 15 }, content: { background: "linear-gradient(0deg, #e7f5ff 0%, rgba(255,255,255,1) 100%)" } }}
            size={"lg"}

        >
            <ModalContent isOverwrite={false} setOpen={setOpen} updates={updates as UpdateNotes} />
        </Modal >
    )
}
