import { UserContext } from '@/utils/context/User.context'
import { Box, Button, Text, ThemeIcon } from '@mantine/core'
import Link from 'next/link'
import React, { PropsWithChildren, useContext } from 'react'
import { UserX } from 'tabler-icons-react'
import UserProvider from './User.provider'

// This provider is used within a UserProvider to check the user has the required permissions.
export default function AdminProvider({ children }: PropsWithChildren) {
    const user = useContext(UserContext)

    if (!user?.ADMIN) {
        return <Box style={{ width: "100%", height: "100vh" }} className="flex aic jcc fdc">
            <ThemeIcon color={"red"} variant="light" size={"xl"}>
                <UserX />
            </ThemeIcon>
            <Box className='flex aic fdc' mt={10}>
                <Text fz="xl" fw={600}>Permission Denied</Text>
                <Text>This is an internal tool used by AgencyAccess staff</Text>
                <Link href={"/"}>
                    <Button variant={"default"} mt={10}>Back Home</Button>
                </Link>
            </Box>
        </Box>
    }
    return <>{children}</>
}
