import parseCustomInviteHeader from '@/utils/helpers/parseCustomInviteHeader'
import { Box, Text } from '@mantine/core'
import React from 'react'
import { Client, ClientLanguage, Invite, PublicClient, Theme } from '../../../../types/global'
import lang from '@/../public/translations/invite.json'
import { useMediaQuery } from '@mantine/hooks'

export default function InviteHeader({ theme, lang: l, client, invite }: { theme: Theme, invite: Invite, client: PublicClient, lang: ClientLanguage }) {
    const mobile = useMediaQuery("(max-width: 460px)")
    return (
        <Box px={mobile ? 15 : 30}>
            <Text ta="center" mt={0} fw={700}>
                <Text component={"span"} fz={25} fw={700}>{
                    theme.wording?.heading
                        ? parseCustomInviteHeader(theme.wording.heading, theme, client as unknown as Client, invite)
                        : `${lang.defaultHeader.title[l]} ${client.name}`
                }</Text>
                <br />
                <Text fw={500} component={"span"} fz="md">{theme.wording?.subheading
                    ? parseCustomInviteHeader(theme.wording.subheading, theme, client as unknown as Client, invite)
                    : `${theme.displayName || invite.agency.name} ${lang.defaultHeader.subtitle[l]}`
                }</Text></Text>
        </Box>
    )
}
