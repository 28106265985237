import { AnyService, Platform, ServiceRole } from "../../../types/global";

export const roleValueToLabel = (account: string, roleValue: string, platforms: Platform[]) => {
    const roles: Partial<Record<AnyService, ServiceRole[]>> = {}
    platforms?.forEach((platform) => {
        platform.services?.forEach((service) => {
            roles[service.name] = service.accessLevels ? [...service.accessLevels] : []
        })
    })
    return roles[account as AnyService]?.find((role) => role.value === roleValue)?.label
}