import { Box, Text } from "@mantine/core"
import Image from "next/image"
import { useRouter } from "next/router"
import { Lock } from "tabler-icons-react"

export const Logo = ({ href, width, dark }: { href?: string, width: number, dark?: boolean }) => {
    const router = useRouter()
    const h = width / 4.63
    return (
        <Image onClick={() => href ? router.push(href) : void 0} className={href ? "cp" : ""} src={`/images/logo${dark ? "_dark" : ""}.png`} width={width} height={h} alt="Agency Access" />
    )
}

export const MiniLogo = ({ width }: { width: number }) => {
    return (
        <Image alt="AgencyAccess Logo" src={"/images/logo_mini.png"} width={width} height={width} />
    )
}