import { ThemeContext } from '@/utils/context/Theme.context'
import { Box, Text, Transition, Tooltip, Paper } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Image from 'next/image'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Key } from 'tabler-icons-react'
import { AccountTypes, AnyService, RequestedAccounts } from '../../../../types/global'
import { useRouter } from 'next/router'
import { InviteContext } from '@/utils/context/Invite.context'
import { SpotlightTour, useSpotlight } from 'react-spotlight-tour'
import Spotlight from 'react-spotlight-tour/spotlight';
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import lang from "@/../public/translations/invite.json"

const Platform = ({ acc, focusedAccount, switchAccount, requestedAccounts }: { requestedAccounts: RequestedAccounts, acc: AccountTypes, focusedAccount: AccountTypes, switchAccount: (a: AccountTypes) => void }) => {
    const [isGranted, setIsGranted] = useState(false)
    const theme = useContext(ThemeContext)
    const isFocused = focusedAccount === acc

    useEffect(() => {
        console.log(requestedAccounts)
        setIsGranted(!Object.keys(requestedAccounts[acc]).filter((s) => !requestedAccounts[acc][s as AnyService].granted && requestedAccounts[acc][s as AnyService].requested).length)
    }, [requestedAccounts])
    return (
        <>
            <Box onClick={() => switchAccount(acc as AccountTypes)} style={{ borderRadius: 10, gap: 5, transition: "all .1s" }} p={"xs"} bg={isFocused ? ((theme.color === "dark" ? (theme.theme === "dark" ? "#fff" : (theme.color || "dark")) : (theme.color || "dark")) || (theme.theme === "light" ? "dark" : "#fff")) : (theme.theme === "dark" ? "dark.7" : "gray.0")} className='flex aic cp'>
                <Paper bg={isGranted ? (isFocused && theme.color === "green" ? "green.8" : "green.6") : (isFocused && theme.color === "orange" ? "orange.8" : "orange.6")} style={{ borderRadius: 100 }} w={10} h={10}></Paper>
                <Image alt={acc} src={`/images/logos/${acc.replaceAll(" ", "_").toLowerCase()}.png`} style={{ objectFit: "contain", marginLeft: 2 }} width={16} height={16} />
                <Text fw={600} fz="sm" lh={1} c={(theme.theme === "dark" && theme.color === "dark" && isFocused) ? "#000" : ((isFocused) || theme.theme === "dark" ? "#fff" : "#000")}>{acc}</Text>
            </Box>
        </>
    )
}

const AccountSelectorBar = ({
    focusedAccount,
    setFocusedAccount,
    accountGrantContainerVis,
    setAccountGrantContainerVis,
    requestedAccounts
}: {
    focusedAccount: AccountTypes,
    setFocusedAccount: (a: AccountTypes) => void,
    accountGrantContainerVis: boolean,
    setAccountGrantContainerVis: (v: boolean) => void,
    requestedAccounts: RequestedAccounts
}) => {
    const [tooltipShown, setTooltipShown] = useState(true)
    const { lang: l } = useContext(InviteClientContext)
    const switchAccount = useCallback((newAccount: AccountTypes) => {
        setAccountGrantContainerVis(false)
        setTimeout(() => {
            setFocusedAccount(newAccount)
            setAccountGrantContainerVis(true)
        }, 200)
    }, [])

    useEffect(() => {
        if (tooltipShown) {
            setTimeout(() => {
                setTooltipShown(false)
            }, 5000)
        }
    }, [tooltipShown])

    return (
        <Box w={"100%"} className='flex aic horizontalOverflow' style={{ gap: 5, overflowX: "auto" }} pb={5}>
            {Object.keys(requestedAccounts).sort().map((acc, i) => {
                return <Box>
                    <Tooltip transitionProps={{ transition: "pop", duration: 300 }} position="left" style={{ zIndex: 999999999999999 }} withArrow opened={!i && tooltipShown} label={
                        <Box style={{ position: "relative" }}>
                            <Box className='flex aic' style={{ gap: 5 }}>
                                <Key size={15} />
                                <Text fw={600} fz="sm">{lang.intoTooltip.title[l]}</Text>
                            </Box>
                            <Text fz="xs" className='flex aic' style={{ gap: 8 }}>
                                <Box bg={"orange.6"} style={{ borderRadius: 100 }} w={10} h={10}></Box>
                                {lang.intoTooltip.noGrantedIndicator[l]}
                            </Text>
                            <Text fz="xs" className='flex aic' style={{ gap: 8 }}>
                                <Box bg={"green.6"} style={{ borderRadius: 100 }} w={10} h={10}></Box>
                                {lang.intoTooltip.grantedIndicator[l]}
                            </Text>
                        </Box>}>
                        <div>
                            <Platform requestedAccounts={requestedAccounts} acc={acc as AccountTypes} focusedAccount={focusedAccount} switchAccount={switchAccount} key={i} />
                        </div>
                    </Tooltip>
                </Box>
            })}
        </Box>
    )
}

export default function AccountsContainer({ requestedAccounts, children }: { requestedAccounts: RequestedAccounts, granted: boolean, children: (account: AccountTypes, setFocusedAccount: (a: AccountTypes) => void) => React.ReactNode }) {
    const router = useRouter()
    const invite = useContext(InviteContext)
    const mobile = useMediaQuery("(max-width: 460px)")
    const accountSelectHeaderShown = Object.keys(requestedAccounts).length > 1
    const [focusedAccount, setFocusedAccount] = useState<AccountTypes>(Object.keys(requestedAccounts).sort()[0] as AccountTypes)
    const [accountGrantContainerVis, setAccountGrantContainerVis] = useState(true)

    useEffect(() => {
        const platformParam = router.query.platform as AccountTypes
        if (platformParam && Object.keys(invite.requestedAccounts).includes(platformParam)) {
            setFocusedAccount(platformParam)
            window.history.pushState({}, document.title, `/i/${invite.id}`)
        }
    }, [router])

    console.log("Focused account", focusedAccount)

    return (
        <Box px={mobile ? 15 : 30} mt={accountSelectHeaderShown ? 35 : 0} w={"100%"} style={{ overflow: "hidden" }} >
            {accountSelectHeaderShown
                ? <AccountSelectorBar requestedAccounts={requestedAccounts} accountGrantContainerVis={accountGrantContainerVis} setAccountGrantContainerVis={setAccountGrantContainerVis} focusedAccount={focusedAccount} setFocusedAccount={setFocusedAccount} />
                : null
            }
            <Transition transition={"pop"} duration={200} mounted={accountGrantContainerVis}>
                {(styles) => (
                    <Box mt={accountSelectHeaderShown ? 20 : 35} className="flex fdc" style={{ width: "100%", gap: 15, borderRadius: 10, ...styles }}>
                        {children(focusedAccount, setFocusedAccount)}
                        {/* <Account id={invite.id} account={focusedAccount} /> */}
                    </Box>
                )}
            </Transition>
        </Box>
    )
}
