import axios, { AxiosHeaders, AxiosStatic, Method } from 'axios';
import { APIResponse } from '../../../types/global';
import { auth } from './firebase';

// const api: (path: string, body?: object | FormData, authenticate?: boolean, method?: "get" | "post") => Promise<APIResponse> = async (path: string, body?: object | FormData, authenticate?: boolean, method?: "get" | "post") => {
//     const url = `${API_BASE_URL}${path}`;

//     // console.log(`${API_BASE_URL}`)

//     let xAuth = undefined
//     if ((authenticate !== false) && auth.currentUser) {
//         const token = await auth.currentUser.getIdToken(true)
//         xAuth = token
//     }
//     // @ts-ignore
//     return axios[method || "post"](url, (!(body instanceof FormData) ? { ...body, agency: window?.localStorage?.getItem("agency") as string } : (!body ? (authenticate !== false ? { agency: window.localStorage.getItem("agency") } : {}) : body)), {
//         headers: {
//             "x-auth": xAuth
//         } as any,
//     }).then((response) => {
//         return response.data as APIResponse;
//     }).catch((err) => {
//         return {
//             error: true,
//             msg: `Something went wrong on our end ${err.response?.status ? `(${err.response?.status})` : ""}`,
//             data: null
//         } as APIResponse
//     });

//     // return response.data;
// };

// export default api

export const API_BASE_URL =
    (process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" || (typeof window !== "undefined" ? !!window.Cypress : false)) ? "https://staging-dot-agencyaccess-bff0f.uc.r.appspot.com" : (process.env.NODE_ENV === 'production')
        ? 'https://agencyaccess-bff0f.uc.r.appspot.com'
        : 'http://localhost:8080';

type RequestFunction = <T>(method: "get" | "post" | "delete", path: string, data?: FormData | object | undefined, headers?: typeof AxiosHeaders, authenticate?: boolean) => Promise<APIResponse<T>>
const request: RequestFunction = async (method, path, data, headers, authenticate) => {
    let xAuth = undefined
    if ((authenticate !== false) && auth.currentUser) {
        const token = await auth.currentUser.getIdToken(true).catch((err) => console.log(err))
        xAuth = token
    }

    let body: Object | FormData;
    if (data instanceof FormData) { // We won't attach agency ID to the body
        body = data
    } else {
        if (!data) {
            if (authenticate !== false) {
                body = { agency: window.localStorage.getItem("agency") as string }
            } else {
                body = {}
            }
        } else {
            body = { ...data, agency: window.localStorage.getItem("agency") as string }
        }
    }

    return axios[method](`${API_BASE_URL}${path}`, method === "post" ? body : {
        headers: {
            "x-auth": xAuth,
            'Cache-Control': 'no-cache',
            ...headers
        } as any,
        params: { ...body },
    }, method === "post" ? {
        headers: {
            "x-auth": xAuth, ...headers
        } as any,
    } : {}).then((response) => {
        return response.data
    }).catch((err) => {
        return {
            error: true,
            msg: `Something went wrong on our end ${err.response?.status ? `(${err.response?.status})` : ""}`,
        }
    })
}


export default {
    get: (...args) => request("get", ...args),
    post: (...args) => request("post", ...args),
    delete: (...args) => request("delete", ...args),
} as {
        [key in "get" | "post" | "delete"]: <T>(path: string, data?: FormData | object | undefined, headers?: typeof AxiosHeaders, authenticate?: boolean) => Promise<APIResponse<T>>
    }