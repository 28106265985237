import api from '@/utils/api'
import { PlatformsContext } from '@/utils/context/Platforms.context'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Platform } from '../../../types/global'

export default function PlatformsProvider({ children }: PropsWithChildren) {
    const [platforms, setPlatforms] = useState<Array<Platform>>([])
    useEffect(() => {
        api.get<Platform[]>("/public/platforms").then((res) => {
            setPlatforms(res.data)
        })
    }, [])

    return (
        // @ts-ignore
        <PlatformsContext.Provider value={platforms}>
            {children}
        </PlatformsContext.Provider>
    )
}
