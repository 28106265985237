import { AgencyContext } from '@/utils/context/Agency.context'
import { UserContext } from '@/utils/context/User.context'
import { dashboardRoutes } from '@/static/routes'
import { ActionIcon, Avatar, Badge, Box, Button, Combobox, Divider, Input, InputBase, Loader, Paper, Text, ThemeIcon, useCombobox } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'
import { Diamond, InfoCircle, Logout, Plus, Settings, UserPlus, Users } from 'tabler-icons-react'
import api from '@/utils/api'
import { notifications } from '@mantine/notifications'

export const NavbarRoute = ({ r, disabled, base }: { r: any, disabled?: boolean, base: string }) => {
    const router = useRouter()
    const [hovered, setHovered] = useState(false)
    const focused = r.path === base ? router.pathname === r.path : router.pathname.startsWith(r.path)

    const R = () => <Box onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} p={10} style={{
        color: "#000",
        background: focused ? "rgba(0,0,0,.05)" : (hovered ? "rgba(0,0,0,.01)" : "transparent"),
        borderRadius: 8,
        opacity: disabled ? .5 : 1
    }} className="cp" onClick={() => {
        if (typeof r.path === "function") r.path();
    }}>
        <div className='flex aic' style={{ gap: 10 }}>
            {React.cloneElement(r.icon, { size: 18, color: focused ? "#000" : "rgba(0,0,0,.6)" })}
            <Text fw={600} fz={"sm"} color={focused ? "#000" : "rgba(0,0,0,.6)"}>{r.label}</Text>
            <div style={{ marginLeft: "auto" }}>{r.new
                ? <Badge color="red" size="xs">NEW</Badge>
                : null
            }</div>
        </div>
    </Box>

    if (disabled || typeof r.path !== "string") return <R />
    return (
        <Link href={r.path}>
            <R />
        </Link>
    )
}

const FreeTrialRequest = () => {
    const [loading, setLoading] = useState(false)
    const [fulfilling, setFulfilling] = useState(false)
    return (
        <Paper bg={"gray.0"} shadow='xs' p="sm" withBorder radius={10}>
            {fulfilling
                ? <Box className='flex aic jcc fdc' py={10}>
                    <Loader size={"xs"} />
                    <Text fw={500} mt={5} fz="sm">Starting free trial...</Text>
                    <Text fz="xs" c="dimmed">This won't take long</Text>
                </Box>
                : <>
                    <Box className='flex aic' style={{ gap: 5 }}>
                        <Diamond strokeWidth={2.5} color='var(--mantine-color-blue-6)' size={19} />
                        <Text fz="sm" fw={600}>Start Free Trial</Text>
                    </Box>
                    <Text fz="xs" mt={3} fw={500}>Get 30 days of AgencyAccess Premium for free.</Text>
                    <Button loading={loading} mt={4} fullWidth size='xs' onClick={() => {
                        setLoading(true)
                        api.post("/stripe/subscribe", {
                            startTrial: true,
                            billingPeriod: "Monthly",
                            plan: "Premium"
                        }).then((res) => {
                            setLoading(false)
                            if (res.error) {
                                return notifications.show({
                                    title: "Error",
                                    message: res.msg,
                                    color: "red"
                                })
                            }
                            setFulfilling(true)
                        })
                    }}>Start Trial</Button>
                </>
            }

        </Paper>
    )
}

export default function Navbar() {
    const router = useRouter()
    const user = useContext(UserContext)
    const agency = useContext(AgencyContext)
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    })
    const updateAgency = (val: string) => {
        if (val === "new") {
            window.localStorage.removeItem("agency")
            router.push("/auth/signup/create")
            return
        }
        window.localStorage.setItem("agency", val)
        window.location.reload()
    }
    const selectedOption = user?.agencies.find((item) => item.id === agency.id);

    const SelectOption = ({ agency }: { agency: { name: string, id: string, pfp: string } }) => {
        const [loading, setLoading] = useState(false)
        return (
            <div className='flex aic jcsb' onClick={() => setLoading(true)}>
                <div className='flex aic' style={{ gap: 10 }}>
                    {loading
                        ? <Box w={38} h={38} className="flex aic jcc"><Loader size={"xs"} /></Box>
                        : <Avatar src={agency.pfp}>{agency.name.substring(0, 1)}</Avatar>
                    }

                    <Text lineClamp={1} fz="sm">{agency.name}</Text>
                </div>
            </div>
        )
    }
    return (
        <Box component={"nav"} miw={240} w={240} className="flex fdc jcsb" p="sm" style={{ borderRight: "1px solid #e9ecef", gap: 5, maxHeight: "calc(100vh - 80px)", overflowY: "auto" }}>
            <div className='flex fdc' style={{ gap: 5 }}>
                {dashboardRoutes.map(r => {
                    return (
                        <NavbarRoute base='/dashboard' r={r} />
                    )
                })}
            </div>
            <div className='flex fdc' style={{ gap: 5 }}>

                {(!agency.trialComplete && agency.plan === "Free")
                    ? <FreeTrialRequest />
                    : null
                }

                <NavbarRoute base='/dashboard' r={{
                    label: "Affiliate Program",
                    path: "/dashboard/affiliate",
                    icon: <UserPlus />
                }} />
                <NavbarRoute base='/dashboard' r={{
                    label: "Manage Team",
                    path: "/dashboard/settings/general?highlight=manage_access",
                    icon: <Users />
                }} />
                <NavbarRoute base='/dashboard' r={{
                    label: "Settings",
                    path: "/dashboard/settings/general",
                    icon: <Settings />
                }} />




                <Combobox
                    store={combobox}
                    withinPortal={true}
                    zIndex={20000}
                    onOptionSubmit={(val) => {
                        updateAgency(val)
                    }}
                >
                    <Combobox.Target>
                        <InputBase
                            mt={10}
                            component="button"
                            type="button"
                            pointer
                            rightSection={<Combobox.Chevron />}
                            onClick={() => combobox.toggleDropdown()}
                            rightSectionPointerEvents="none"
                            multiline
                            radius={10}
                        >
                            {selectedOption ? (
                                <SelectOption agency={selectedOption} />
                            ) : (
                                <Input.Placeholder>Select an agency</Input.Placeholder>
                            )}
                        </InputBase>
                    </Combobox.Target>
                    <Combobox.Dropdown style={{ overflowY: "auto", maxHeight: 500 }} className='verticalOverflow'>
                        <Combobox.Options>{user?.agencies.map((a) => {
                            return (
                                <Combobox.Option value={a.id as string}>
                                    <SelectOption agency={a} />
                                </Combobox.Option>
                            )
                        })}
                            <Divider my={5} opacity={.4} />
                            <Combobox.Option value={"new"}>
                                <div className='flex aic jcsb'>
                                    <div className='flex aic' style={{ gap: 10 }}>
                                        <ThemeIcon size={38} radius="xl" variant={"light"} color={"gray"}>
                                            <Plus />
                                        </ThemeIcon>
                                        <Text fz="sm" fw={500}>Create New</Text>
                                    </div>
                                </div>
                            </Combobox.Option>
                        </Combobox.Options>
                    </Combobox.Dropdown>
                </Combobox>

                <Divider my={5} opacity={.5} />


                <Paper p="xs" className='flex aic jcsb' style={{ overflow: "hidden", gap: 10 }}>
                    {/* <div className='flex aic' style={{ gap: 10, flex: 1, overflow: "hidden", maxWidth: "100%" }}> */}
                    <Avatar radius={100} alt={user?.name} src={user?.pfp}>{user?.name.substring(0, 1)}</Avatar>
                    <div style={{ flex: 1, overflow: "hidden" }}>
                        <Text fz={"sm"} fw={500} lineClamp={1}>{user?.name}</Text>
                        <Text c="dimmed" truncate="end" fz={"xs"} >{user?.email}</Text>
                    </div>
                    <div>
                        <Link href={"/settings"} style={{ flex: 1 }}>
                            <ActionIcon>
                                <Settings size={20} />
                            </ActionIcon>
                        </Link>
                        <Link href={"/logout"} style={{ flex: 1 }}>
                            <ActionIcon>
                                <Logout size={20} />
                            </ActionIcon>
                        </Link>
                    </div>
                    {/* </div> */}
                </Paper>
            </div>
        </Box>
    )
}
