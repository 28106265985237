import { useContext, useState } from "react";
import { AccountTypes, AnyService, BaseServiceConfig, Invite, Platform, StaticKnowledgebaseError } from "../../../../types/global";
import { PlatformsContext } from "../context/Platforms.context";
import { GrantsStatus } from "@/components/invite/GrantsContainer";
import api from "../api";
import { notifications } from "@mantine/notifications";
import { ThemeContext } from "../context/Theme.context";
import { Check } from "tabler-icons-react";
import { Image } from "@mantine/core";

const grantURIs = {
    "Meta": "/grant/facebook",
    "Google": "/grant/google"
} as { [key in AccountTypes]: string }

export const useGrantRequest = () => {
    const platforms = useContext(PlatformsContext)
    const theme = useContext(ThemeContext)
    const [loading, setLoading] = useState(false)

    const [errorList, setErrorList] = useState<string[]>([])
    const [knowledgebaseErrors, setKnowledgebaseErrors] = useState<StaticKnowledgebaseError[]>([])

    const [error, setError] = useState("")

    const sendGrantRequest: (inv: Invite, sc: BaseServiceConfig, p: AccountTypes) => Promise<void> = async (invite, serviceConfigs, account) => new Promise((resolve, reject) => {
        setLoading(true)
        setError("")
        setErrorList([])
        setKnowledgebaseErrors([])

        const newServiceCongigs: BaseServiceConfig = {};

        (Object.keys(serviceConfigs) as Array<AnyService>).map((service: AnyService) => {
            console.log(service, invite.requestedAccounts[account][service]?.granted, !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === service)?.manual)
            if (invite.requestedAccounts[account][service]?.granted || !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === service)?.manual) {
                return
            }
            newServiceCongigs[service] = serviceConfigs[service]
        });

        const grantURI = grantURIs[account]

        api.post(grantURI, {
            serviceConfig: newServiceCongigs,
            inviteID: invite.id
        }).then((res) => {
            if (res.error) {
                setLoading(false)
                reject(res.msg)
                setError(res.msg)
                return
            }

            const responseData = res.data as {
                successfulServiceGrants?: AnyService[],
                errors: { grantErrors?: Array<string>, knowledgebaseErrors?: StaticKnowledgebaseError[] }
            }
            setLoading(false)
            if (responseData.errors.grantErrors?.length) {
                setErrorList(responseData.errors.grantErrors)
            }
            if (responseData.errors.knowledgebaseErrors?.length) {
                setKnowledgebaseErrors(responseData.errors.knowledgebaseErrors)
            }

            responseData.successfulServiceGrants?.forEach((service) => {
                notifications.show({
                    color: theme.color,
                    title: `${service} Granted`,
                    message: `Access to ${service} was granted successfully`,
                    icon: <Image width={22} height={22} src={`/images/logos/${service.replaceAll(" ", "_").toLowerCase()}.png`} style={{ objectFit: "contain" }} />
                })
            })

            resolve()
        })
    })

    return {
        sendGrantRequest,
        loading,
        setLoading,
        error,
        setError,
        errorList,
        setErrorList,
        knowledgebaseErrors,
        setKnowledgebaseErrors
    }
}