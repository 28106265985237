import { Box, Loader, Paper, Text, TextInput } from '@mantine/core'
import React from 'react'
import { CircleCheck, CircleX } from 'tabler-icons-react'

export default function ThankYouRedirectInput({ redirect, setRedirect, redirectValid }: { redirect: string, setRedirect: (m: string) => void, redirectValid: boolean | undefined }) {
    return (
        <Box className='flex aic' style={{ borderRadius: 10, overflow: "hidden", flex: 1 }}>
            <Paper h={36} withBorder className='flex aic jcc' bg={"gray.0"} px={10} style={{ borderRight: "none", borderRadius: 0, borderColor: "#ced4da", borderTopLeftRadius: 10, borderBottomLeftRadius: 10, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                <Text fz="sm">https://</Text>
            </Paper>
            <TextInput value={redirect.replace("https://", "")} onChange={(e) => setRedirect(`${e.target.value}`)} placeholder='example.com' styles={{
                input: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                },
            }} onBlur={() => {
                if (redirect.split("://").length > 1) {
                    setRedirect(redirect.split("://")[1])
                }
            }} style={{ flex: 1 }} rightSection={redirect ? (redirectValid === undefined ? <Loader size={"xs"} /> : (redirectValid ? <CircleCheck size={20} color={"var(--mantine-color-green-6)"} /> : <CircleX size={20} color={"var(--mantine-color-red-6)"} />)) : null} />
        </Box>
    )
}
