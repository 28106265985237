import { Box, Text } from '@mantine/core'
import Link from 'next/link'
import React, { useContext } from 'react'
import { Logo } from '../Logo'
import { ThemeContext } from '@/utils/context/Theme.context'
import lang from '@/../public/translations/invite.json'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { InviteContext } from '@/utils/context/Invite.context'
import { ClientLanguage } from '../../../../types/global'

export default function Footer({ overwriteLang, overwriteEmail }: { overwriteLang?: ClientLanguage, overwriteEmail?: string }) {
    const theme = useContext(ThemeContext)
    const invite = useContext(InviteContext)
    const client = useContext(InviteClientContext)
    const l = overwriteLang || client?.lang || "English"

    return (
        <>
            <Box mt={20}>
                <Text fw={600} fz={"sm"} c="dimmed">{lang.footer.help[l]} <Text target="_blank" href={`mailto:${overwriteEmail || invite?.agency?.email || "kevin@agencyaccess.co"}`} span fw={600} fz="sm" c="blue" className='cp' component={Link}>{invite?.agency?.email || "kevin@agencyaccess.co"}</Text></Text>
            </Box>
            <div style={{ paddingBottom: 30, marginTop: 30 }} className="flex aic jcc fdc">
                <Text c="dimmed" fz={"sm"}>{lang.footer.poweredBy[l]}</Text>
                <Link href={"https://agencyaccess.co"}>
                    <div style={{ filter: "grayscale(100%)" }}>
                        <Logo dark={theme.theme === "dark"} width={150} />
                    </div>
                </Link>
                <Link href={"https://agencyaccess.co"}>
                    <Text fz="xs" c="dimmed" style={{ textDecoration: "underline" }}>{lang.footer.ad[l]}</Text>
                </Link>
            </div>
        </>
    )
}
