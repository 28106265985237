import { Button, Tooltip } from '@mantine/core'
import Image from 'next/image'
import React, { useContext, useEffect, useState } from 'react'
import { AccountTypes, AnyService, GoogleServices, MetaServices, RequestedAccounts } from '../../../../types/global'
import { InviteContext } from '@/utils/context/Invite.context'
import { SubdomainContext } from '@/utils/context/Subdomain.context'
import { UserContext } from '@/utils/context/User.context'
import { useRouter } from 'next/router'
import getServices from '@/utils/data/getInviteRequestedServices'
import filterRequestedAccounts from '@/utils/data/filterRequestedAccounts'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import lang from '@/../public/translations/invite.json'
const resolveWindow = (url: string, isEmbed: boolean, platform: AccountTypes) => {
    if (isEmbed) {
        return window.open(`${window.location.origin}/portal?platform=${platform}&redirect=${encodeURIComponent(url)}`, `AgencyAccess ${platform} OAuth Redirect`, "height=800,width=500")
    }
    window.location.replace(url)
}

const MetaOauthCallback = (id: string, requestedAccounts: RequestedAccounts, sub?: string, test?: boolean, isEmbed?: boolean) => {
    const scopeMap = {
        "Facebook Pages": ["pages_show_list"],
        "Facebook Product Catalog": ["catalog_management"],
        "Meta Ads": ["ads_read"],
        "Meta Pixels": ["ads_read"],
        "Instagram": []
    } as Record<MetaServices, string[]>
    const scopeString = [...new Set(
        Object.keys(filterRequestedAccounts(requestedAccounts).Meta).map((s) => scopeMap[s as MetaServices].join(" "))
    )].join(" ")
    const url = encodeURI(
        `https://www.facebook.com/v18.0/dialog/oauth?client_id=${314827657731889}&redirect_uri=${`${process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ? "https://agency-access-git-staging-agency-access.vercel.app" : (process.env.NODE_ENV === "production" ? "https://www.agencyaccess.co" : "http://localhost:3001")}/i/oauth/facebook`}&state=${JSON.stringify([id, sub, !!isEmbed])}&scope=${test ? `${"business_management catalog_management pages_show_list ads_read public_profile pages_read_engagement"}` : `${`business_management email public_profile ${scopeString}`}`}`
    )
    resolveWindow(url, !!isEmbed, "Meta")
}

const GoogleOAuthCallback = (id: string, requestedAccounts: RequestedAccounts, sub?: string, isEmbed?: boolean) => {
    const scopeMap = {
        "Google Ads": ["https://www.googleapis.com/auth/adwords"],
        "Google Ads MCC": ["https://www.googleapis.com/auth/adwords"],
        "Google Analytics": ["https://www.googleapis.com/auth/analytics.manage.users", "https://www.googleapis.com/auth/analytics.edit"],
        "Google Business Profile": ["https://www.googleapis.com/auth/business.manage"],
        "Google Tag Manager": ["https://www.googleapis.com/auth/tagmanager.manage.users", "https://www.googleapis.com/auth/tagmanager.readonly"],
        "Google Merchant Center": ["https://www.googleapis.com/auth/content"],
        "Google Search Console": ["https://www.googleapis.com/auth/webmasters"],
        "YouTube Studio": [],
        "Google DV360": []
    } as Record<GoogleServices, string[]>
    const scopeString = [...new Set(
        Object.keys(filterRequestedAccounts(requestedAccounts).Google).map((s) => scopeMap[s as GoogleServices].join(" "))
    )].join(" ")
    console.log(scopeString)
    console.log(`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&scope=${(`openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile ${scopeString}`)}&include_granted_scopes=true&state=${JSON.stringify([id, sub, !!isEmbed])}&redirect_uri=${(`${process.env.NODE_ENV === "production" ? "https://agencyaccess.co" : "http://localhost:3001"}/i/oauth/google`)}&client_id=${("919394265473-g8k6kgi77k5tdofb4it437mko3au8mk2.apps.googleusercontent.com")}&prompt=consent`)
    const url = encodeURI(
        `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&scope=${(`openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile ${scopeString}`)}&include_granted_scopes=true&state=${JSON.stringify([id, sub, !!isEmbed])}&redirect_uri=${(`${process.env.NODE_ENV === "production" ? "https://agencyaccess.co" : "http://localhost:3001"}/i/oauth/google`)}&client_id=${("919394265473-g8k6kgi77k5tdofb4it437mko3au8mk2.apps.googleusercontent.com")}&prompt=consent`
    )
    resolveWindow(url, !!isEmbed, "Google")
}

export default function PlatformAuthButton({ account, preview, requestedAccounts }: { account: AccountTypes, preview?: boolean, requestedAccounts: RequestedAccounts }) {
    const invite = useContext(InviteContext)
    const { lang: l } = useContext(InviteClientContext)
    const subdomain = useContext(SubdomainContext)
    const router = useRouter()
    const { mode } = router.query

    const [tooltipHasShown, setTooltipHasShown] = useState(false)
    const [tooltipShown, setTooltipShow] = useState(false)

    useEffect(() => {
        if (preview || tooltipHasShown) return;
        if (invite.creds?.[account]?.access_token) {
            setTimeout(() => {
                setTooltipShow(true)
                setTooltipHasShown(true)
                setTimeout(() => {
                    setTooltipShow(false)
                }, 4000);

            }, 5000);
        }
    }, [invite.creds])

    return (
        <Tooltip withinPortal position="top-end" withArrow multiline opened={tooltipShown} label={lang.switchAccountsTooltip[l]}>
            <Button w={"fit-content"} variant={"default"} leftSection={<Image alt={account} width={25} height={25} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#fff", padding: 5 }} src={`/images/logos/${account?.toLowerCase().replaceAll(" ", "_")}.png`} />}
                onClick={() => {
                    if (preview) return
                    switch (account) {
                        case "Google":
                            GoogleOAuthCallback(invite.id, requestedAccounts, subdomain, mode === "embed")
                            break
                        case "Meta":
                            MetaOauthCallback(invite.id, requestedAccounts, subdomain, invite.agency.email === "ethan@agencyaccess.co" || invite.agency.email === "support@agencyaccess.co", mode === "embed")
                            break
                    }
                }}>{!preview && invite.creds?.[account]?.access_token ? lang.authButtons.authenticated[l] : `${lang.authButtons.notAuthenticated[l]} ${account}`}</Button>

        </Tooltip>
    )
}
