import { ActionIcon, Modal, ModalProps } from '@mantine/core'
import { useElementSize } from '@mantine/hooks';
import React, { PropsWithChildren } from 'react'
import { X } from 'tabler-icons-react';

type ExtendedModalProps = {
    fixedWidth: number,
    fixedHeight?: number | string,
    fixedHeader?: React.ReactElement,
    setOpen: (o: boolean) => void,
    overlay?: ((h: number) => React.ReactElement) | null
}

const ContentRenderer = ({ children, fixedWidth, fixedHeight, fixedHeader, setOpen, overlay }: PropsWithChildren & ExtendedModalProps) => {
    const { ref, height } = useElementSize();
    const { ref: containerRef, height: containerHeight } = useElementSize();


    return (
        <div ref={containerRef} style={{
            transition: "all .2s",
            borderRadius: 10,
            overflow: "hidden",
            width: fixedWidth,
            position: "relative",
            maxHeight: "100%",
            // background: "red",
            margin: "0 auto",
        }} className='flex fdc'>
            {overlay ? overlay(height) : null}
            {fixedHeader}
            <div style={{ maxHeight: height, transition: "all .3s", background: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, flex: 1, overflowY: height > containerHeight ? "auto" : "hidden", overflowX: "hidden", position: 'relative' }}>
                <div ref={ref} style={{ height: "fit-content" }}>
                    <ActionIcon onClick={() => setOpen(false)} style={{ position: "absolute", right: 20, top: 20, zIndex: 9999 }}>
                        <X size={20} />
                    </ActionIcon>
                    <div style={{ padding: 0, height: fixedHeight || "fit-content" }}>
                        {children}
                    </div>

                </div>
            </div>

            {/* @ts-ignore */}
        </div>
    )
}

export default function AutosizeModal(props: ModalProps & ExtendedModalProps) {
    return (
        <Modal
            opened={props.opened}
            centered
            onClose={props.onClose}
            withCloseButton={false}
            padding={0}
            zIndex={2000}
            size="auto"
            id={props.id}
            styles={{
                content: {
                    width: '100%',
                    maxWidth: "fit-content",
                    height: "100%",
                    background: "transparent",
                    boxShadow: "none",
                },
                body: {
                    height: "100%",
                    display: "flex",
                    margin: "0 auto",
                    overflow: "hidden",
                    justifyContent: 'center',
                }
            }}
        >
            <ContentRenderer {...props}>
                {props.children}
            </ContentRenderer>
        </Modal>
    )
}
