import { doc, getDoc } from "firebase/firestore"
import { Theme } from "../../../../types/global"
import { firestore } from "../firebase"

const defaultTheme = {
    theme: "light",
    logoWidthPercentage: "50%"
} as Theme

export default (agencyID: string) => new Promise<Theme | {}>((res) => {
    getDoc(doc(firestore, "themes", agencyID)).then((snap) => {
        if (!snap.exists()) {
            res(defaultTheme)
        }
        res(snap.data() as Theme)
    }).catch(() => res(defaultTheme))
})