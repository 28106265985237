// @ts-nocheck
import CopyBox from "@/components/CopyBox";
import { InlinePageLink } from "@/components/InlineLink";
import { Button, Indicator, Text, TextInput, Box, Paper, Highlight, Popover, Image, Divider, CopyButton, Avatar, Tooltip } from "@mantine/core";
import React, { ReactElement, useRef, useState } from "react";
import { Check, Copy, ExternalLink, InfoSquare, Settings, UserPlus } from "tabler-icons-react";
import { AnyService, ClientLanguage, GoogleServices, Invite, InviteCreds, MetaServices, PinterestServices, Theme, TwitterServices } from "../../../types/global";
import Link from "next/link";

export type InstructionProps = {
    resource?: string[],
    accessLevel?: string,
    inviteIdentifier?: string,
    creds: InviteCreds,
    form?: {
        label: string,
        id: string,
        value: string | string[],
        placeholder?: string
    }[],
    agencyName?: string
    inviteData?: Invite,
    oAuthProviderButton?: React.ReactElement,
    assetSelector?: React.ReactElement,
    theme: Theme,
    setFormValue?: (id: string, val: string | string[]) => void
}


const InlineInput = ({ value, setValue, placeholder }: { value: string, placeholder: string, setValue: (v: string) => void }) => {
    return <Popover withArrow shadow="sm" radius={10}>
        <Popover.Target>
            <Highlight fw={500} p={2} px={5} bg={!value ? "red.1" : "blue.1"} color={!value ? "red.1" : "blue.1"} style={{ borderRadius: 5 }} span fz="sm" className="cp" highlight={value || placeholder}>{value || placeholder}</Highlight>
        </Popover.Target>
        <Popover.Dropdown p={0}>
            <TextInput autoFocus value={value} onChange={(e) => setValue(e.target.value)} styles={{ input: { border: "none" } }} placeholder="Enter value" />
        </Popover.Dropdown>
    </Popover>
}
type MultiLang<T> = Record<ClientLanguage, T>

export type Instruction = {
    index: number,
    title: MultiLang<string>,
    description: MultiLang<((props: InstructionProps) => ReactElement)>,
    rightSection?: MultiLang<((props: InstructionProps) => ReactElement)>,
    image?: string,
    decisionPoint?: {
        id: string,
        choices: Array<{
            label: MultiLang<string>,
            value: string
        }>
    },
    condition?: {
        type: string,
        value: string
    },
    // decisionPoint?: string, // If this step is tied to a decision point, we'll decide to render it based on this
    decisionContext?: Array<string>
}

export default {


    "Google Search Console": [
        {
            index: 0,
            title: {
                English: "Sign in with Google",
                Spanish: "Iniciar sesión con Google",
                Dutch: "Inloggen met Google",
            },
            description: {
                English: ({ oAuthProviderButton }) => <>
                    <Text mb={10} fz="sm">Click the <strong>Sign in with Google</strong> button</Text>
                </>,
                Spanish: ({ oAuthProviderButton }) => <>
                    <Text mb={10} fz="sm">Haga clic en el botón <strong>Iniciar sesión con Google</strong></Text>
                </>,
                Dutch: ({ oAuthProviderButton }) => <>
                    <Text mb={10} fz="sm">Klik op de knop <strong>Inloggen met Google</strong></Text>
                </>,
            },
            rightSection: {
                English: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>,
                Spanish: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>,
                Dutch: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>,
            },
        },
        {
            index: 1,
            title: {
                English: "Select domains",
                Spanish: "Seleccionar dominios",
                Dutch: "Domeinen selecteren",
            },
            description: {
                English: () => <Text fz="sm">
                    Select the domain you'd like to give access to from the dropdown above
                </Text>,
                Spanish: () => <Text fz="sm">
                    Seleccione el dominio al que desea dar acceso desde el menú desplegable de arriba
                </Text>,
                Dutch: () => <Text fz="sm">
                    Selecteer het domein waartoe u toegang wilt geven in de bovenstaande vervolgkeuzelijst
                </Text>,
            },
            rightSection: {
                English: ({ assetSelector }) => <>{assetSelector}</>,
                Spanish: ({ assetSelector }) => <>{assetSelector}</>,
                Dutch: ({ assetSelector }) => <>{assetSelector}</>,
            },
        },
        {
            index: 2,
            title: {
                English: "Navigate to the console",
                Spanish: "Navegar a la consola",
                Dutch: "Navigeer naar de console",
            },
            description: {
                English: ({ resource }) => <Text fz="sm">
                    Navigate to <InlinePageLink newTab href={`https://search.google.com/search-console/users${resource ? `?resource_id=${resource}` : ""}`}>Google Search Console</InlinePageLink>. It should look something like this.
                </Text>,
                Spanish: ({ resource }) => <Text fz="sm">
                    Navegue a <InlinePageLink newTab href={`https://search.google.com/search-console/users${resource ? `?resource_id=${resource}` : ""}`}>Google Search Console</InlinePageLink>. Debería verse algo así.
                </Text>,
                Dutch: ({ resource }) => <Text fz="sm">
                    Navigeer naar <InlinePageLink newTab href={`https://search.google.com/search-console/users${resource ? `?resource_id=${resource}` : ""}`}>Google Search Console</InlinePageLink>. Het zou er zo uit moeten zien.
                </Text>,
            },
            image: "1.png"
        },
        {
            index: 3,
            title: {
                English: "Grant Access",
                Spanish: "Conceder acceso",
                Dutch: "Toegang verlenen",
            },
            description: {
                English: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">Click the <strong>Add User</strong> button.</Text>
                        <Text fz="sm">In the email field, type:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />

                        <Text mt={5} fz="sm">In the permission field, type <strong>{accessLevel}</strong></Text>
                        <br />
                        <Text fz="sm">Click <strong>Add</strong> and wait for the indicator above to change from <Text fz="sm" span fw={600} c="orange">Waiting</Text> to <Text fz="sm" span fw={600} c="blue">Successful</Text></Text>
                    </>
                ),
                Spanish: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">Haga clic en el botón <strong>Agregar usuario</strong>.</Text>
                        <Text fz="sm">En el campo de correo electrónico, escriba:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />

                        <Text mt={5} fz="sm">En el campo de permisos, escriba <strong>{accessLevel}</strong></Text>
                        <br />
                        <Text fz="sm">Haga clic en <strong>Añadir</strong> y espere a que el indicador de arriba cambie de <Text fz="sm" span fw={600} c="orange">Esperando</Text> a <Text fz="sm" span fw={600} c="blue">Exitoso</Text></Text>
                    </>
                ),
                Dutch: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">Klik op de knop <strong>Gebruiker toevoegen</strong>.</Text>
                        <Text fz="sm">Typ in het e-mailveld:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />

                        <Text mt={5} fz="sm">Typ in het machtigingsveld <strong>{accessLevel}</strong></Text>
                        <br />
                        <Text fz="sm">Klik op <strong>Toevoegen</strong> en wacht tot de indicator hierboven verandert van <Text fz="sm" span fw={600} c="orange">Wachten</Text> naar <Text fz="sm" span fw={600} c="blue">Succesvol</Text></Text>
                    </>
                ),
            }
        }
    ],


    "YouTube Studio": [
        {
            index: 0,
            title: {
                English: "Navigate to YouTube studio",
                Spanish: "Navegar a YouTube Studio",
                Dutch: "Ga naar YouTube Studio"
            },
            description: {
                English: () => <Text fz="sm">Navigate to <InlinePageLink newTab href="https://studio.youtube.com">YouTube Studio</InlinePageLink>. Make sure you are logged in to the correct Google Account and are viewing the correct channel. It should look something like this.</Text>,
                Spanish: () => <Text fz="sm">Navegar a <InlinePageLink newTab href="https://studio.youtube.com">YouTube Studio</InlinePageLink>. Asegúrese de que ha iniciado sesión en la cuenta de Google correcta y está viendo el canal correcto. Debería lucir algo así.</Text>,
                Dutch: () => <Text fz="sm">Ga naar <InlinePageLink newTab href="https://studio.youtube.com">YouTube Studio</InlinePageLink>. Zorg ervoor dat u bent ingelogd op het juiste Google-account en het juiste kanaal bekijkt. Het zou er zo uit moeten zien.</Text>
            },
            image: "1.png"
        },
        {
            index: 1,
            title: {
                English: "Open Settings",
                Spanish: "Abrir Configuración",
                Dutch: "Open Instellingen"
            },
            description: {
                English: () => <Text fz="sm">Click the <Text fz="sm" fw={600} span style={{ gap: 5 }}>{<Settings size={15} />} Settings</Text> button in the bottom left corner of your screen</Text>,
                Spanish: () => <Text fz="sm">Haga clic en el botón <Text fz="sm" fw={600} span style={{ gap: 5 }}>{<Settings size={15} />} Configuración</Text> en la esquina inferior izquierda de su pantalla</Text>,
                Dutch: () => <Text fz="sm">Klik op de knop <Text fz="sm" fw={600} span style={{ gap: 5 }}>{<Settings size={15} />} Instellingen</Text> in de linkerbenedenhoek van uw scherm</Text>
            }
        },
        // Continue similarly for the rest of the services and the special fields with decisionPoint or conditional logic.
        {
            index: 2,
            title: {
                English: "Determine your account type",
                Spanish: "Determine su tipo de cuenta",
                Dutch: "Bepaal uw accounttype"
            },
            description: {
                English: () => <Text fz="sm">Select the appropriate option to continue</Text>,
                Spanish: () => <Text fz="sm">Seleccione la opción apropiada para continuar</Text>,
                Dutch: () => <Text fz="sm">Selecteer de juiste optie om door te gaan</Text>
            },
            decisionPoint: {
                id: "account_type",
                choices: [
                    {
                        value: "personal",
                        label: {
                            English: "I can manage my permissions here",
                            Spanish: "Puedo gestionar mis permisos aquí",
                            Dutch: "Ik kan mijn permissies hier beheren"
                        }
                    },
                    {
                        value: "brand",
                        label: {
                            English: `I see "You're currently managing channel permissions through a Brand account."`,
                            Spanish: `Veo "Actualmente estás gestionando los permisos del canal a través de una cuenta de marca."`,
                            Dutch: `Ik zie "Je beheert de kanaaltoegangsrechten momenteel via een merkenaccount."`
                        }
                    }
                ]
            }
        },


        {
            index: 3,
            title: {
                English: "Navigate to brand account settings",
                Spanish: "Navegue a configuraciones de cuenta de marca",
                Dutch: "Ga naar merkaccountinstellingen",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        Click the <strong>Manage Permissions</strong> button
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Haga clic en el botón <strong>Administrar permisos</strong>
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Klik op de knop <strong>Machtigingen beheren</strong>
                    </Text>
                ),
            },
            image: "4.png",
            condition: {
                type: "account_type",
                value: "brand",
            },
        },
        {
            index: 4,
            title: {
                English: "Click Manage Permissions",
                Spanish: "Haga clic en Administrar permisos",
                Dutch: "Klik op Machtigingen beheren",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        You should see a page that looks like this. Click the{" "}
                        <strong>Manage Permissions</strong> button under users. You may be
                        prompted to enter your password.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Debería ver una página que se parece a esta. Haga clic en el botón{" "}
                        <strong>Administrar permisos</strong> debajo de usuarios. Es
                        posible que se le solicite que ingrese su contraseña.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        U zou een pagina moeten zien die er ongeveer zo uitziet. Klik op de{" "}
                        <strong>Machtigingen beheren</strong> knop onder gebruikers. U zou
                        mogelijk worden gevraagd om uw wachtwoord in te voeren.
                    </Text>
                ),
            },
            image: "3.png",
            condition: {
                type: "account_type",
                value: "brand",
            },
        },
        {
            index: 5,
            title: {
                English: "Invite a user",
                Spanish: "Invitar a un usuario",
                Dutch: "Nodig een gebruiker uit",
            },
            description: {
                English: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Click the <UserPlus size={18} /> icon at the top right of the
                            modal. In the email box, type:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text mt={5} fz="sm">
                            In the role box, select <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Click <strong>Invite</strong> and you're all done!
                        </Text>
                    </>
                ),
                Spanish: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Haga clic en el icono <UserPlus size={18} /> en la parte superior
                            derecha del modal. En el cuadro de correo electrónico, escriba:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text mt={5} fz="sm">
                            En el cuadro de función, seleccione{" "}
                            <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Haga clic en <strong>Invitar</strong> ¡y ya está todo listo!
                        </Text>
                    </>
                ),
                Dutch: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Klik op het <UserPlus size={18} />-pictogram rechtsboven in de
                            modal. Typ in het e-mailvak:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text mt={5} fz="sm">
                            Selecteer in het rolvak <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">Klik op <strong>Uitnodigen</strong> en je bent klaar!</Text>
                    </>
                ),
            },
            condition: {
                type: "account_type",
                value: "brand",
            },
        },
        {
            index: 3,
            title: {
                English: "Navigate to Invite Modal",
                Spanish: "Ir al Modal de Invitación",
                Dutch: "Naar Uitnodigingsvenster",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        Click the invite button at the top right of the modal. You should
                        see a popup like this.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Haz clic en el botón de invitar en la parte superior derecha del
                        modal. Deberías ver un mensaje emergente como este.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Klik op de uitnodigingsknop rechtsboven in de modal. Je zou een
                        popup als deze moeten zien.
                    </Text>
                ),
            },
            image: "5.png",
            condition: {
                type: "account_type",
                value: "personal",
            },
        },
        {
            index: 4,
            title: {
                English: "Invite a user",
                Spanish: "Invitar a un usuario",
                Dutch: "Nodig een gebruiker uit",
            },
            description: {
                English: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">In the email box, type:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            In the access box, select <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Click <strong>Done</strong> and you're finished!
                        </Text>
                    </>
                ),
                Spanish: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">En el cuadro de correo electrónico, escriba:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            En el cuadro de acceso, seleccione{" "}
                            <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Haga clic en <strong>Hecho</strong> ¡y ha terminado!
                        </Text>
                    </>
                ),
                Dutch: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">Typ in het e-mailvak:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            Selecteer in het toegangsvak <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Klik op <strong>Gereed</strong> en je bent klaar!
                        </Text>
                    </>
                ),
            },
            image: "5.png",
            condition: {
                type: "account_type",
                value: "personal",
            },
        },
    ],




    "Twitter Delegate Account": [
        {
            index: 0,
            title: {
                English: "Navigate to delegated members",
                Spanish: "Navegar a miembros delegados",
                Dutch: "Ga naar gedelegeerde leden",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        Navigate to{" "}
                        <InlinePageLink
                            newTab
                            href="https://twitter.com/settings/delegate/members"
                        >
                            X delegated member settings
                        </InlinePageLink>
                        . Make sure you're logged into X. It should look something like
                        this.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Navegue a{" "}
                        <InlinePageLink
                            newTab
                            href="https://twitter.com/settings/delegate/members"
                        >
                            configuraciones de miembros delegados de X
                        </InlinePageLink>
                        . Asegúrese de haber iniciado sesión en X. Debería verse algo como
                        esto.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Navigeer naar{" "}
                        <InlinePageLink
                            newTab
                            href="https://twitter.com/settings/delegate/members"
                        >
                            X instellingen voor gedelegeerde leden
                        </InlinePageLink>
                        . Zorg ervoor dat je bent ingelogd op X. Het zou er ongeveer zo uit
                        moeten zien.
                    </Text>
                ),
            },
            image: "3.png",
        },
        {
            index: 1,
            title: {
                English: "Invite a member",
                Spanish: "Invitar a un miembro",
                Dutch: "Nodig een lid uit",
            },
            description: {
                English: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Click the <strong>invite a member button</strong>.
                        </Text>
                        <Text fz="sm" mt={5}>
                            In the search box, type:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text mt={5} fz="sm">
                            After selecting the correct account, assign the{" "}
                            <strong>{accessLevel}</strong> role to your new member. Click{" "}
                            <strong>Send Invite</strong> and you're good to go!
                        </Text>
                    </>
                ),
                Spanish: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Haga clic en el botón{" "}
                            <strong>invitar a un miembro</strong>.
                        </Text>
                        <Text fz="sm" mt={5}>
                            En el cuadro de búsqueda, escriba:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text mt={5} fz="sm">
                            Después de seleccionar la cuenta correcta, asigne el rol{" "}
                            <strong>{accessLevel}</strong> a su nuevo miembro. Haga clic en{" "}
                            <strong>Enviar invitación</strong> ¡y listo!
                        </Text>
                    </>
                ),
                Dutch: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Klik op de knop <strong>nodig een lid uit</strong>.
                        </Text>
                        <Text fz="sm" mt={5}>
                            Typ in het zoekvak:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text mt={5} fz="sm">
                            Nadat je het juiste account hebt geselecteerd, wijs je de rol{" "}
                            <strong>{accessLevel}</strong> toe aan je nieuwe lid. Klik op{" "}
                            <strong>Verzend uitnodiging</strong> en je bent klaar!
                        </Text>
                    </>
                ),
            },
        },
    ],




    "Twitter Ads": [
        {
            index: 0,
            title: {
                English: "Navigate to X Ads",
                Spanish: "Navegar a X Ads",
                Dutch: "Ga naar X Ads",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        <InlinePageLink newTab href="https://ads.twitter.com">
                            Navigate to X ads
                        </InlinePageLink>
                        . Make sure you're logged into the correct account.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        <InlinePageLink newTab href="https://ads.twitter.com">
                            Navegue a X Ads
                        </InlinePageLink>
                        . Asegúrese de haber iniciado sesión en la cuenta correcta.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        <InlinePageLink newTab href="https://ads.twitter.com">
                            Ga naar X-advertenties
                        </InlinePageLink>
                        . Zorg ervoor dat u bent ingelogd op het juiste account.
                    </Text>
                ),
            },
        },
        {
            index: 1,
            title: {
                English: "Navigate to account settings",
                Spanish: "Navegar a la configuración de la cuenta",
                Dutch: "Ga naar accountinstellingen",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        Click your account name in the top right of the header. In the
                        dropdown, click <strong>Edit access to account</strong>.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Haga clic en el nombre de su cuenta en la esquina superior derecha
                        del encabezado. En el menú desplegable, haga clic en{" "}
                        <strong>Editar acceso a la cuenta</strong>.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Klik op uw accountnaam rechtsboven in de koptekst. Klik in het
                        dropdown menu op <strong>Toegang tot account bewerken</strong>.
                    </Text>
                ),
            },
            image: "1.png",
        },
        {
            index: 2,
            title: {
                English: "Open add access",
                Spanish: "Abrir agregar acceso",
                Dutch: "Toegang toevoegen openen",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        You should see a screen similar to this. Click the{" "}
                        <strong>Add access</strong> button to the right of your screen.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Debería ver una pantalla similar a esta. Haga clic en el botón{" "}
                        <strong>Agregar acceso</strong> a la derecha de su pantalla.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        U zou een scherm moeten zien die hierop lijkt. Klik op de knop{" "}
                        <strong>Toegang toevoegen</strong> aan de rechterkant van uw
                        scherm.
                    </Text>
                ),
            },
            image: "2.png",
        },
        {
            index: 3,
            title: {
                English: "Grant access",
                Spanish: "Conceder acceso",
                Dutch: "Toegang verlenen",
            },
            description: {
                English: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">In the name textbox, type:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            In the access dropdown, select <strong>{accessLevel}</strong>
                        </Text>
                        <Text fz="sm">
                            Click the <strong>Save Changes</strong> button and you're good to
                            go!
                        </Text>
                    </>
                ),
                Spanish: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">En el cuadro de texto de nombre, escriba:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            En el menú desplegable de acceso, seleccione{" "}
                            <strong>{accessLevel}</strong>
                        </Text>
                        <Text fz="sm">
                            Haga clic en el botón <strong>Guardar cambios</strong> ¡y listo!
                        </Text>
                    </>
                ),
                Dutch: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">Typ in het naamtekstvak:</Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            Selecteer in de toegangsdropdown <strong>{accessLevel}</strong>
                        </Text>
                        <Text fz="sm">
                            Klik op de knop <strong>Wijzigingen opslaan</strong> en je bent
                            klaar!
                        </Text>
                    </>
                ),
            },
        },
    ],





    "Instagram": [
        {
            index: 0,
            title: {
                English: "Sign in with Meta",
                Spanish: "Iniciar sesión con Meta",
                Dutch: "Aanmelden met Meta",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        Click the <strong>Sign in with Meta</strong> button
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Haga clic en el botón <strong>Iniciar sesión con Meta</strong>
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Klik op de knop <strong>Aanmelden met Meta</strong>
                    </Text>
                ),
            },
            rightSection: {
                English: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>,
                Spanish: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>,
                Dutch: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>,
            },
        },
        {
            index: 1,
            title: {
                English: "Select a business account",
                Spanish: "Seleccionar una cuenta de negocio",
                Dutch: "Selecteer een zakelijk account",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        Choose the business account you'd like to grant access to from the
                        dropdown above
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Elija la cuenta de negocio a la que le gustaría otorgar acceso del
                        menú desplegable de arriba
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Kies het zakelijke account waartoe u toegang wilt verlenen uit de
                        bovenstaande dropdown
                    </Text>
                ),
            },
            rightSection: {
                English: ({ assetSelector }) => <>{assetSelector}</>,
                Spanish: ({ assetSelector }) => <>{assetSelector}</>,
                Dutch: ({ assetSelector }) => <>{assetSelector}</>,
            },
        },
        {
            index: 2,
            title: {
                English: "Navigate to Business Settings",
                Spanish: "Navegar a Configuración de Negocios",
                Dutch: "Ga naar Bedrijfsinstellingen",
            },
            description: {
                English: ({ resource }) => (
                    <Text>
                        Navigate to{" "}
                        <InlinePageLink
                            newTab
                            href={`https://business.facebook.com/settings/partners${resource ? `?business_id=${resource[0]}` : ""
                                }`}
                        >
                            Meta Business Settings
                        </InlinePageLink>
                        . It should look something like this.
                    </Text>
                ),
                Spanish: ({ resource }) => (
                    <Text>
                        Navegue a{" "}
                        <InlinePageLink
                            newTab
                            href={`https://business.facebook.com/settings/partners${resource ? `?business_id=${resource[0]}` : ""
                                }`}
                        >
                            Configuración de Meta Business
                        </InlinePageLink>
                        . Debería verse algo así.
                    </Text>
                ),
                Dutch: ({ resource }) => (
                    <Text>
                        Navigeer naar{" "}
                        <InlinePageLink
                            newTab
                            href={`https://business.facebook.com/settings/partners${resource ? `?business_id=${resource[0]}` : ""
                                }`}
                        >
                            Meta Bedrijfsinstellingen
                        </InlinePageLink>
                        . Het zou er ongeveer zo uit moeten zien.
                    </Text>
                ),
            },
            image: "1.png",
        },
        // Please continue filling the rest of the translations for the remaining elements
        // like Snapchat Ads, Pinterest Ads, etc., similar to the above pattern.
    ],


    "Snapchat Ads": [
        {
            index: 0,
            title: {
                English: "Navigate to Ads Manager",
                Spanish: "Navegar a Ads Manager",
                Dutch: "Ga naar Ads Manager",
            },
            description: {
                English: () => (
                    <Text fz={"sm"}>
                        Navigate to{" "}
                        <InlinePageLink href="https://ads.snapchat.com/" newTab>
                            Snapchat Ads Manager
                        </InlinePageLink>{" "}
                        and log in.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz={"sm"}>
                        Navega a{" "}
                        <InlinePageLink href="https://ads.snapchat.com/" newTab>
                            Ads Manager de Snapchat
                        </InlinePageLink>{" "}
                        e inicia sesión.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz={"sm"}>
                        Ga naar{" "}
                        <InlinePageLink href="https://ads.snapchat.com/" newTab>
                            Snapchat Ads Manager
                        </InlinePageLink>{" "}
                        en log in.
                    </Text>
                ),
            },
        },
        {
            index: 1,
            title: {
                English: "Pick an option",
                Spanish: "Elige una opción",
                Dutch: "Kies een optie",
            },
            description: {
                English: ({ agencyName }) => (
                    <Text fz={"sm"}>
                        Have you already invited {agencyName} to your Snapchat Business
                        account?
                    </Text>
                ),
                Spanish: ({ agencyName }) => (
                    <Text fz={"sm"}>
                        ¿Ya has invitado a {agencyName} a tu cuenta de Snapchat Business?
                    </Text>
                ),
                Dutch: ({ agencyName }) => (
                    <Text fz={"sm"}>
                        Heb je {agencyName} al uitgenodigd voor je Snapchat Business-account?
                    </Text>
                ),
            },
            decisionPoint: {
                id: "is_invited",
                choices: [
                    {
                        label: {
                            English: "Yes",
                            Spanish: "Sí",
                            Dutch: "Ja",
                        },
                        value: "yes",
                    },
                    {
                        label: {
                            English: "No",
                            Spanish: "No",
                            Dutch: "Nee",
                        },
                        value: "no",
                    },
                ],
            },
        },
        {
            index: 2,
            title: {
                English: "Navigate to Members",
                Spanish: "Navegar a Miembros",
                Dutch: "Ga naar Leden",
            },
            description: {
                English: () => (
                    <Text fz={"sm"}>
                        Once you're logged in, navigate to{" "}
                        <strong>Members</strong> using the menu in the top left.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz={"sm"}>
                        Una vez que hayas iniciado sesión, navega a{" "}
                        <strong>Miembros</strong> usando el menú en la parte superior
                        izquierda.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz={"sm"}>
                        Zodra je bent ingelogd, navigeer je naar{" "}
                        <strong>Leden</strong> via het menu in de linkerbovenhoek.
                    </Text>
                ),
            },
            condition: {
                type: "is_invited",
                value: "no",
            },
            image: "4.png",
        },
        {
            index: 3,
            title: {
                English: "Invite a Member",
                Spanish: "Invitar a un Miembro",
                Dutch: "Een Lid Uitnodigen",
            },
            description: {
                English: ({ inviteData, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Click the <strong>Invite Members</strong> button at the top right of
                            the screen.
                        </Text>
                        <Text fz={"sm"}>In the email field, type:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            Under <strong>Role</strong>, select{" "}
                            <strong>{inviteData?.requestedAccounts.Snapchat["Snapchat Business"].accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Click <strong>Invite Members</strong> and move on to the next step.
                        </Text>
                    </>
                ),
                Spanish: ({ inviteData, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Haz clic en el botón <strong>Invitar Miembros</strong> en la parte
                            superior derecha de la pantalla.
                        </Text>
                        <Text fz={"sm"}>En el campo de correo electrónico, escribe:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            En <strong>Rol</strong>, selecciona{" "}
                            <strong>{inviteData?.requestedAccounts.Snapchat["Snapchat Business"].accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Haz clic en <strong>Invitar Miembros</strong> y continúa con el
                            siguiente paso.
                        </Text>
                    </>
                ),
                Dutch: ({ inviteData, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Klik op de knop <strong>Leden Uitnodigen</strong> rechtsboven op het
                            scherm.
                        </Text>
                        <Text fz={"sm"}>Typ in het e-mailveld:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            Kies onder <strong>Rol</strong> voor{" "}
                            <strong>{inviteData?.requestedAccounts.Snapchat["Snapchat Business"].accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Klik op <strong>Leden Uitnodigen</strong> en ga verder naar de
                            volgende stap.
                        </Text>
                    </>
                ),
            },
            condition: {
                type: "is_invited",
                value: "no",
            },
            image: "5.png",
        },
        {
            index: 2,
            title: {
                English: "Navigate to Ad Accounts",
                Spanish: "Navegar a Cuentas Publicitarias",
                Dutch: "Ga naar Advertentieaccounts",
            },
            description: {
                English: () => (
                    <Text fz={"sm"}>
                        Once you're logged in, navigate to <strong>Ad Accounts</strong> using
                        the menu in the top left.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz={"sm"}>
                        Una vez conectado, navega a <strong>Cuentas Publicitarias</strong>{" "}
                        usando el menú en la parte superior izquierda.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz={"sm"}>
                        Zodra je bent ingelogd, navigeer je naar{" "}
                        <strong>Advertentieaccounts</strong> via het menu linksboven.
                    </Text>
                ),
            },
            image: "1.png",
        },
        {
            index: 3,
            title: {
                English: "Navigate to Members",
                Spanish: "Navegar a Miembros",
                Dutch: "Ga naar Leden",
            },
            description: {
                English: () => (
                    <Text fz={"sm"}>
                        Select your ad account from the list, then select{" "}
                        <strong>Members and Billing</strong> in the left hand panel.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz={"sm"}>
                        Selecciona tu cuenta publicitaria de la lista y luego selecciona{" "}
                        <strong>Miembros y Facturación</strong> en el panel de la izquierda.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz={"sm"}>
                        Selecteer je advertentieaccount uit de lijst en selecteer vervolgens{" "}
                        <strong>Leden en Facturering</strong> in het linker paneel.
                    </Text>
                ),
            },
            image: "2.png",
        },
        {
            index: 4,
            title: {
                English: "Add a Member",
                Spanish: "Agregar un Miembro",
                Dutch: "Een Lid Toevoegen",
            },
            description: {
                English: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Click the <strong>Invite Members</strong> button at the top right of
                            the screen.
                        </Text>
                        <Text fz={"sm"}>In the email field, type:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            Under <strong>Role</strong>, select <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Click <strong>Invite Members</strong> and you're all done!
                        </Text>
                    </>
                ),
                Spanish: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Haz clic en el botón <strong>Invitar Miembros</strong> en la parte
                            superior derecha de la pantalla.
                        </Text>
                        <Text fz={"sm"}>En el campo de correo electrónico, escribe:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            Bajo <strong>Rol</strong>, selecciona <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Haz clic en <strong>Invitar Miembros</strong> y ¡has terminado!
                        </Text>
                    </>
                ),
                Dutch: ({ inviteIdentifier, accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Klik op de knop <strong>Leden Uitnodigen</strong> rechtsboven op het
                            scherm.
                        </Text>
                        <Text fz={"sm"}>Typ in het e-mailveld:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            Kies onder <strong>Rol</strong> voor <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Klik op <strong>Leden Uitnodigen</strong> en je bent helemaal klaar!
                        </Text>
                    </>
                ),
            },
            image: "3.png",
        },
    ],









    "Amazon Ads": [
        {
            index: 0,
            title: {
                English: "Navigate to the dashboard",
                Spanish: "Navegar al panel de control",
                Dutch: "Ga naar het dashboard",
            },
            description: {
                English: () => (
                    <>
                        <Text fz="sm">
                            Navigate to the{" "}
                            <InlinePageLink newTab href="https://advertising.amazon.com/">
                                Amazon Ads dashboard
                            </InlinePageLink>{" "}
                            and log in.
                        </Text>
                        <Text fz="sm">
                            Once you're in the dashboard, click <strong>Administration</strong>{" "}
                            in the bottom left. Then click{" "}
                            <strong>Account Access & Settings</strong>
                        </Text>
                    </>
                ),
                Spanish: () => (
                    <>
                        <Text fz="sm">
                            Navegue al{" "}
                            <InlinePageLink newTab href="https://advertising.amazon.com/">
                                panel de control de Amazon Ads
                            </InlinePageLink>{" "}
                            e inicie sesión.
                        </Text>
                        <Text fz="sm">
                            Una vez que estés en el panel de control, haz clic en{" "}
                            <strong>Administración</strong> en la esquina inferior izquierda.
                            Luego haz clic en <strong>Acceso a Cuenta y Configuración</strong>
                        </Text>
                    </>
                ),
                Dutch: () => (
                    <>
                        <Text fz="sm">
                            Navigeer naar de{" "}
                            <InlinePageLink newTab href="https://advertising.amazon.com/">
                                Amazon Ads-dashboard
                            </InlinePageLink>{" "}
                            en log in.
                        </Text>
                        <Text fz="sm">
                            Zodra je in het dashboard bent, klik je linksonder op{" "}
                            <strong>Beheer</strong>. Klik vervolgens op{" "}
                            <strong>Accounttoegang en instellingen</strong>
                        </Text>
                    </>
                ),
            },
            image: "1.png",
        },
        {
            index: 1,
            title: {
                English: "Find your account ID",
                Spanish: "Encuentra tu ID de cuenta",
                Dutch: "Vind je account-ID",
            },
            description: {
                English: ({ form, setFormValue }) => (
                    <>
                        <Text fz={"sm"}>
                            Once in <strong>Account Access & Settings</strong>, you should see
                            your account ID right under your account name.
                        </Text>
                        <br />
                        <Text fz={"sm"}>
                            Copy the account ID and paste it into the textbox below.
                        </Text>
                        {form?.map((input) => (
                            <TextInput
                                value={input.value[0]}
                                placeholder={input.placeholder}
                                mt={10}
                                label={input.label}
                                onChange={(e) => {
                                    if (!setFormValue) return;
                                    setFormValue(input.id, [e.target.value]);
                                }}
                            />
                        ))}
                    </>
                ),
                Spanish: ({ form, setFormValue }) => (
                    <>
                        <Text fz={"sm"}>
                            Una vez en <strong>Acceso a Cuenta y Configuración</strong>, deberías
                            ver tu ID de cuenta justo debajo de tu nombre de cuenta.
                        </Text>
                        <br />
                        <Text fz={"sm"}>
                            Copia el ID de la cuenta y pégalo en el cuadro de texto a continuación.
                        </Text>
                        {form?.map((input) => (
                            <TextInput
                                value={input.value[0]}
                                placeholder={input.placeholder}
                                mt={10}
                                label={input.label}
                                onChange={(e) => {
                                    if (!setFormValue) return;
                                    setFormValue(input.id, [e.target.value]);
                                }}
                            />
                        ))}
                    </>
                ),
                Dutch: ({ form, setFormValue }) => (
                    <>
                        <Text fz={"sm"}>
                            Eenmaal in <strong>Accounttoegang en instellingen</strong>, zou je je
                            account-ID direct onder je accountnaam moeten zien.
                        </Text>
                        <br />
                        <Text fz={"sm"}>
                            Kopieer het account-ID en plak het in het onderstaande tekstvak.
                        </Text>
                        {form?.map((input) => (
                            <TextInput
                                value={input.value[0]}
                                placeholder={input.placeholder}
                                mt={10}
                                label={input.label}
                                onChange={(e) => {
                                    if (!setFormValue) return;
                                    setFormValue(input.id, [e.target.value]);
                                }}
                            />
                        ))}
                    </>
                ),
            },
            image: "2.png",
        },
        {
            index: 2,
            title: {
                English: "Grant access",
                Spanish: "Conceder acceso",
                Dutch: "Toegang verlenen",
            },
            description: {
                English: ({ inviteIdentifier, agencyName }) => {
                    if (!inviteIdentifier) {
                        return (
                            <Box className="flex" style={{ gap: 18 }}>
                                <Indicator mt={8} ml={10} processing color="orange" />
                                <Box>
                                    <Text lh={1} fw={600} fz="lg">Come back soon...</Text>
                                    <Text mt={4} fz="sm">
                                        We've passed on your account ID to {agencyName}. They'll generate an Amazon Ads
                                        confirmation URL for you which you can use to finish granting access. You'll get an
                                        email notification when the URL is ready to go!
                                    </Text>
                                </Box>
                            </Box>
                        );
                    }

                    return (
                        <>
                            <Text fz={"sm"}>
                                <strong>Click the button below to grant access</strong> via the Amazon Ads dashboard. Make
                                sure you're logged into the correct account.
                            </Text>
                        </>
                    );
                },
                Spanish: ({ inviteIdentifier, agencyName }) => {
                    if (!inviteIdentifier) {
                        return (
                            <Box className="flex" style={{ gap: 18 }}>
                                <Indicator mt={8} ml={10} processing color="orange" />
                                <Box>
                                    <Text lh={1} fw={600} fz="lg">Regresa pronto...</Text>
                                    <Text mt={4} fz="sm">
                                        Hemos pasado tu ID de cuenta a {agencyName}. Generarán una URL de confirmación de
                                        Amazon Ads para ti, que puedes usar para finalizar la concesión de acceso. ¡Recibirás
                                        una notificación por correo electrónico cuando la URL esté lista!
                                    </Text>
                                </Box>
                            </Box>
                        );
                    }

                    return (
                        <>
                            <Text fz={"sm"}>
                                <strong>Haz clic en el botón de abajo para conceder acceso</strong> a través del
                                panel de control de Amazon Ads. Asegúrate de haber iniciado sesión en la cuenta correcta.
                            </Text>
                        </>
                    );
                },
                Dutch: ({ inviteIdentifier, agencyName }) => {
                    if (!inviteIdentifier) {
                        return (
                            <Box className="flex" style={{ gap: 18 }}>
                                <Indicator mt={8} ml={10} processing color="orange" />
                                <Box>
                                    <Text lh={1} fw={600} fz="lg">Kom snel terug...</Text>
                                    <Text mt={4} fz="sm">
                                        We hebben je account-ID doorgestuurd naar {agencyName}. Zij zullen een Amazon Ads
                                        bevestigings-URL voor je maken die je kunt gebruiken om de toegang te voltooien. Je ontvangt
                                        een e-mailnotificatie zodra de URL klaar is!
                                    </Text>
                                </Box>
                            </Box>
                        );
                    }

                    return (
                        <>
                            <Text fz={"sm"}>
                                <strong>Klik op de knop hieronder om toegang te verlenen</strong> via het Amazon Ads-dashboard. Zorg
                                ervoor dat je bent ingelogd op het juiste account.
                            </Text>
                        </>
                    );
                },
            },
        },
    ],









    "Shopify Store Collaborator": [
        {
            index: 0,
            title: {
                English: "Navigate to the dashboard",
                Spanish: "Navegar al panel de control",
                Dutch: "Ga naar het dashboard",
            },
            description: {
                English: () => (
                    <>
                        <Text fz={"sm"}>
                            Navigate to the{" "}
                            <InlinePageLink newTab href={`https://admin.shopify.com`}>
                                Shopify Dashboard
                            </InlinePageLink>{" "}
                            and log in to your store.
                        </Text>
                        <Text fz="sm">
                            Once you're in the dashboard, click <strong>Settings</strong> in the
                            bottom left. Then click <strong>Users and permissions</strong>
                        </Text>
                    </>
                ),
                Spanish: () => (
                    <>
                        <Text fz={"sm"}>
                            Navegue al{" "}
                            <InlinePageLink newTab href={`https://admin.shopify.com`}>
                                panel de control de Shopify
                            </InlinePageLink>{" "}
                            e inicie sesión en su tienda.
                        </Text>
                        <Text fz="sm">
                            Una vez que estés en el panel de control, haz clic en{" "}
                            <strong>Configuración</strong> en la esquina inferior izquierda.
                            Luego haz clic en <strong>Usuarios y permisos</strong>
                        </Text>
                    </>
                ),
                Dutch: () => (
                    <>
                        <Text fz={"sm"}>
                            Navigeer naar het{" "}
                            <InlinePageLink newTab href={`https://admin.shopify.com`}>
                                Shopify-dashboard
                            </InlinePageLink>{" "}
                            en log in op je winkel.
                        </Text>
                        <Text fz="sm">
                            Zodra je in het dashboard bent, klik je linksonder op{" "}
                            <strong>Instellingen</strong>. Klik vervolgens op{" "}
                            <strong>Gebruikers en permissies</strong>
                        </Text>
                    </>
                ),
            },
            image: "1.gif",
        },
        {
            index: 1,
            title: {
                English: "Find your Collaborator Code",
                Spanish: "Encuentra tu código de colaborador",
                Dutch: "Vind je samenwerkingscode",
            },
            description: {
                English: ({ form, setFormValue }) => (
                    <>
                        <Text fz="sm">
                            Find your <strong>Collaborator Request Code</strong> and copy &
                            paste it into the textbox below.
                        </Text>
                        {form?.map((input) =>
                            input.id === "client_account_id" ? (
                                <TextInput
                                    value={input.value[0]}
                                    placeholder={input.placeholder}
                                    mt={7}
                                    label={input.label}
                                    onChange={(e) => {
                                        if (!setFormValue) return;
                                        setFormValue(input.id, [e.target.value]);
                                    }}
                                />
                            ) : null
                        )}
                        <Text mt={5} fz="sm">
                            This code is used to invite the requesting agency to your account.
                        </Text>
                    </>
                ),
                Spanish: ({ form, setFormValue }) => (
                    <>
                        <Text fz="sm">
                            Encuentra tu <strong>Código de Solicitud de Colaborador</strong> y
                            cópialo y pégalo en el cuadro de texto a continuación.
                        </Text>
                        {form?.map((input) =>
                            input.id === "client_account_id" ? (
                                <TextInput
                                    value={input.value[0]}
                                    placeholder={input.placeholder}
                                    mt={7}
                                    label={input.label}
                                    onChange={(e) => {
                                        if (!setFormValue) return;
                                        setFormValue(input.id, [e.target.value]);
                                    }}
                                />
                            ) : null
                        )}
                        <Text mt={5} fz="sm">
                            Este código se utiliza para invitar a la agencia solicitante a tu
                            cuenta.
                        </Text>
                    </>
                ),
                Dutch: ({ form, setFormValue }) => (
                    <>
                        <Text fz="sm">
                            Vind je <strong>Saamwerkingsverzoekcode</strong> en kopieer en plak
                            deze in het onderstaande tekstvak.
                        </Text>
                        {form?.map((input) =>
                            input.id === "client_account_id" ? (
                                <TextInput
                                    value={input.value[0]}
                                    placeholder={input.placeholder}
                                    mt={7}
                                    label={input.label}
                                    onChange={(e) => {
                                        if (!setFormValue) return;
                                        setFormValue(input.id, [e.target.value]);
                                    }}
                                />
                            ) : null
                        )}
                        <Text mt={5} fz="sm">
                            Deze code wordt gebruikt om de verzoekende instantie uit te nodigen
                            voor je account.
                        </Text>
                    </>
                ),
            },
            image: "2.gif",
        },
        {
            index: 2,
            title: {
                English: "Find your Store URL",
                Spanish: "Encuentra la URL de tu tienda",
                Dutch: "Vind je winkel-URL",
            },
            description: {
                English: ({ form, setFormValue, theme }) => (
                    <>
                        <Text fz="sm">
                            If you know your store URL already, enter it in the textbox below.
                            Otherwise, in settings, navigate to <strong>Domains</strong>, and
                            enter the active domain for your store.
                        </Text>
                        {form?.map((input) =>
                            input.id === "store_url" ? (
                                <TextInput
                                    color={theme.color || "dark"}
                                    value={input.value[0]}
                                    placeholder={input.placeholder}
                                    mt={7}
                                    label={input.label}
                                    onChange={(e) => {
                                        if (!setFormValue) return;
                                        setFormValue(input.id, [e.target.value]);
                                    }}
                                />
                            ) : null
                        )}
                    </>
                ),
                Spanish: ({ form, setFormValue, theme }) => (
                    <>
                        <Text fz="sm">
                            Si ya conoces la URL de tu tienda, introdúcela en el cuadro de texto
                            a continuación. De lo contrario, en la configuración, navega a{" "}
                            <strong>Dominios</strong>, e introduce el dominio activo de tu
                            tienda.
                        </Text>
                        {form?.map((input) =>
                            input.id === "store_url" ? (
                                <TextInput
                                    color={theme.color || "dark"}
                                    value={input.value[0]}
                                    placeholder={input.placeholder}
                                    mt={7}
                                    label={input.label}
                                    onChange={(e) => {
                                        if (!setFormValue) return;
                                        setFormValue(input.id, [e.target.value]);
                                    }}
                                />
                            ) : null
                        )}
                    </>
                ),
                Dutch: ({ form, setFormValue, theme }) => (
                    <>
                        <Text fz="sm">
                            Als je de URL van je winkel al weet, voer deze dan in het
                            onderstaande tekstvak in. Navigeer anders in de instellingen naar{" "}
                            <strong>Domeinen</strong> en voer het actieve domein voor je winkel
                            in.
                        </Text>
                        {form?.map((input) =>
                            input.id === "store_url" ? (
                                <TextInput
                                    color={theme.color || "dark"}
                                    value={input.value[0]}
                                    placeholder={input.placeholder}
                                    mt={7}
                                    label={input.label}
                                    onChange={(e) => {
                                        if (!setFormValue) return;
                                        setFormValue(input.id, [e.target.value]);
                                    }}
                                />
                            ) : null
                        )}
                    </>
                ),
            },
            image: "3.gif",
        },
        {
            index: 3,
            title: {
                English: "Grant Access",
                Spanish: "Conceder acceso",
                Dutch: "Toegang verlenen",
            },
            description: {
                English: ({ inviteIdentifier, agencyName }) => {
                    if (!inviteIdentifier) {
                        return (
                            <Box className="flex" style={{ gap: 18 }}>
                                <Indicator mt={8} ml={10} processing color="orange" />
                                <Box>
                                    <Text lh={1} fw={600} fz="lg">
                                        Come back soon...
                                    </Text>
                                    <Text mt={4} fz="sm">
                                        We've passed on your collaborator code to {agencyName}.
                                        They'll generate a confirmation request for you which you can
                                        accept to finish granting access. You'll get an email
                                        notification from us when the request is ready to accept.
                                    </Text>
                                </Box>
                            </Box>
                        );
                    }

                    return (
                        <>
                            <Text fz={"sm"}>
                                Navigate to the <strong>Shopify Dashboard</strong> and accept the
                                incoming collaborator request.
                            </Text>
                        </>
                    );
                },
                Spanish: ({ inviteIdentifier, agencyName }) => {
                    if (!inviteIdentifier) {
                        return (
                            <Box className="flex" style={{ gap: 18 }}>
                                <Indicator mt={8} ml={10} processing color="orange" />
                                <Box>
                                    <Text lh={1} fw={600} fz="lg">
                                        Vuelve pronto...
                                    </Text>
                                    <Text mt={4} fz="sm">
                                        Hemos pasado tu código de colaborador a {agencyName}. Ellos
                                        generarán una solicitud de confirmación que podrás aceptar
                                        para finalizar la concesión de acceso. Recibirás una
                                        notificación por correo electrónico de nuestra parte cuando la
                                        solicitud esté lista para aceptar.
                                    </Text>
                                </Box>
                            </Box>
                        );
                    }

                    return (
                        <>
                            <Text fz={"sm"}>
                                Navega al <strong>Panel de Control de Shopify</strong> y acepta la
                                solicitud de colaboración entrante.
                            </Text>
                        </>
                    );
                },
                Dutch: ({ inviteIdentifier, agencyName }) => {
                    if (!inviteIdentifier) {
                        return (
                            <Box className="flex" style={{ gap: 18 }}>
                                <Indicator mt={8} ml={10} processing color="orange" />
                                <Box>
                                    <Text lh={1} fw={600} fz="lg">
                                        Kom snel terug...
                                    </Text>
                                    <Text mt={4} fz="sm">
                                        We hebben je samenwerkingscode doorgestuurd naar {agencyName}.
                                        Zij zullen een bevestigingsverzoek voor je genereren dat je
                                        kunt accepteren om de toegang te voltooien. Je ontvangt een
                                        e-mailnotificatie van ons zodra het verzoek klaar is voor
                                        acceptatie.
                                    </Text>
                                </Box>
                            </Box>
                        );
                    }

                    return (
                        <>
                            <Text fz={"sm"}>
                                Navigeer naar het <strong>Shopify-dashboard</strong> en accepteer
                                het inkomende samenwerkingsverzoek.
                            </Text>
                        </>
                    );
                },
            },
        },
    ],




    "Snapchat Business": [
        {
            index: 0,
            title: {
                English: "Navigate to Ads Manager",
                Spanish: "Navegar a Ads Manager",
                Dutch: "Ga naar Ads Manager",
            },
            description: {
                English: () => (
                    <Text fz={"sm"}>
                        Navigate to{" "}
                        <InlinePageLink href="https://ads.snapchat.com/" newTab>
                            Snapchat Business Manager
                        </InlinePageLink>{" "}
                        and log in.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz={"sm"}>
                        Navega a{" "}
                        <InlinePageLink href="https://ads.snapchat.com/" newTab>
                            Snapchat Business Manager
                        </InlinePageLink>{" "}
                        e inicia sesión.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz={"sm"}>
                        Ga naar{" "}
                        <InlinePageLink href="https://ads.snapchat.com/" newTab>
                            Snapchat Business Manager
                        </InlinePageLink>{" "}
                        en log in.
                    </Text>
                ),
            },
        },
        {
            index: 1,
            title: {
                English: "Navigate to Members",
                Spanish: "Navegar a Miembros",
                Dutch: "Ga naar Leden",
            },
            description: {
                English: () => (
                    <Text fz={"sm"}>
                        Once you're logged in, navigate to <strong>Members</strong> using the
                        menu in the top left.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz={"sm"}>
                        Una vez que hayas iniciado sesión, navega a{" "}
                        <strong>Miembros</strong> usando el menú en la parte superior
                        izquierda.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz={"sm"}>
                        Zodra je bent ingelogd, navigeer je naar{" "}
                        <strong>Leden</strong> via het menu in de linkerbovenhoek.
                    </Text>
                ),
            },
            image: "1.png",
        },
        {
            index: 2,
            title: {
                English: "Invite a Member",
                Spanish: "Invitar a un Miembro",
                Dutch: "Een Lid Uitnodigen",
            },
            description: {
                English: ({ accessLevel, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Click the <strong>Invite Members</strong> button at the top right of
                            the screen.
                        </Text>
                        <Text fz={"sm"}>In the email field, type:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            Under <strong>Role</strong>, select <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Click <strong>Invite Members</strong> and you're done!
                        </Text>
                    </>
                ),
                Spanish: ({ accessLevel, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Haz clic en el botón <strong>Invitar Miembros</strong> en la parte
                            superior derecha de la pantalla.
                        </Text>
                        <Text fz={"sm"}>En el campo de correo electrónico, escribe:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            Bajo <strong>Rol</strong>, selecciona <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Haz clic en <strong>Invitar Miembros</strong> y ¡has terminado!
                        </Text>
                    </>
                ),
                Dutch: ({ accessLevel, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Klik op de knop <strong>Leden Uitnodigen</strong> rechtsboven op het
                            scherm.
                        </Text>
                        <Text fz={"sm"}>Typ in het e-mailveld:</Text>
                        <CopyBox value={inviteIdentifier as string} mt={5} />
                        <Text fz="sm" mt={10}>
                            Kies onder <strong>Rol</strong> voor <strong>{accessLevel}</strong>.
                        </Text>
                        <Text fz="sm">
                            Klik op <strong>Leden Uitnodigen</strong> en je bent klaar!
                        </Text>
                    </>
                ),
            },
            image: "2.png",
        },
    ],

    "HubSpot Partner": [
        {
            index: 0,
            title: {
                English: "Follow access link",
                Spanish: "Seguir enlace de acceso",
                Dutch: "Volg toegangskoppeling",
            },
            description: {
                English: ({ inviteIdentifier }) => (
                    <Text fz="sm">
                        Follow{" "}
                        <InlinePageLink href={inviteIdentifier as string} newTab>
                            this link
                        </InlinePageLink>{" "}
                        and select the account you want to share. Once access has been
                        granted, click the confirmation button below.
                    </Text>
                ),
                Spanish: ({ inviteIdentifier }) => (
                    <Text fz="sm">
                        Sigue{" "}
                        <InlinePageLink href={inviteIdentifier as string} newTab>
                            este enlace
                        </InlinePageLink>{" "}
                        y selecciona la cuenta que deseas compartir. Una vez que se haya
                        concedido el acceso, haz clic en el botón de confirmación a
                        continuación.
                    </Text>
                ),
                Dutch: ({ inviteIdentifier }) => (
                    <Text fz="sm">
                        Volg{" "}
                        <InlinePageLink href={inviteIdentifier as string} newTab>
                            deze koppeling
                        </InlinePageLink>{" "}
                        en selecteer het account dat je wilt delen. Zodra de toegang is
                        verleend, klik je op de bevestigingsknop hieronder.
                    </Text>
                ),
            },
        },
    ],






    "Pinterest Ads": [
        {
            index: 0,
            title: {
                English: "Navigate to Business Manager",
                Spanish: "Navegar a Business Manager",
                Dutch: "Ga naar Bedrijfsmanager",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        Navigate to{" "}
                        <InlinePageLink
                            newTab
                            href="https://www.pinterest.co.uk/business/business-manager"
                        >
                            Pinterest Business Manager
                        </InlinePageLink>
                        . Make sure you're logged into the correct account.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Navega a{" "}
                        <InlinePageLink
                            newTab
                            href="https://www.pinterest.co.uk/business/business-manager"
                        >
                            Pinterest Business Manager
                        </InlinePageLink>
                        . Asegúrate de haber iniciado sesión en la cuenta correcta.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Navigeer naar{" "}
                        <InlinePageLink
                            newTab
                            href="https://www.pinterest.co.uk/business/business-manager"
                        >
                            Pinterest Bedrijfsmanager
                        </InlinePageLink>
                        . Zorg ervoor dat u bent ingelogd op het juiste account.
                    </Text>
                ),
            },
        },
        {
            index: 1,
            title: {
                English: "Navigate to partners",
                Spanish: "Navegar a socios",
                Dutch: "Ga naar partners",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        In the sidebar, select <strong>Partners</strong>
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        En la barra lateral, selecciona <strong>Socios</strong>
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Selecteer in de zijbalk <strong>Partners</strong>
                    </Text>
                ),
            },
            image: "1.png",
        },
        {
            index: 2,
            title: {
                English: "Invite partner",
                Spanish: "Invitar a socio",
                Dutch: "Partner uitnodigen",
            },
            description: {
                English: ({ inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Click <strong>Add a partner</strong>
                        </Text>
                        <Text fz="sm">
                            In the modal, under <strong>Partner Business ID</strong>, enter:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            Then select{" "}
                            <strong>
                                Invite this partner to access your ad accounts and tools
                            </strong>{" "}
                            and click <strong>Add Partner</strong>
                        </Text>
                    </>
                ),
                Spanish: ({ inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Haz clic en <strong>Agregar un socio</strong>
                        </Text>
                        <Text fz="sm">
                            En el modal, bajo <strong>ID de Negocio del Socio</strong>, ingresa:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            Luego selecciona{" "}
                            <strong>
                                Invitar a este socio para acceder a tus cuentas publicitarias y
                                herramientas
                            </strong>{" "}
                            y haz clic en <strong>Agregar socio</strong>
                        </Text>
                    </>
                ),
                Dutch: ({ inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Klik op <strong>Partner toevoegen</strong>
                        </Text>
                        <Text fz="sm">
                            Voer in de modal onder <strong>Partner bedrijfs-ID</strong> in:
                        </Text>
                        <CopyBox mt={5} value={inviteIdentifier as string} />
                        <Text fz="sm" mt={5}>
                            Selecteer dan{" "}
                            <strong>
                                Nodig deze partner uit om toegang te krijgen tot je advertentieaccounts
                                en tools
                            </strong>{" "}
                            en klik op <strong>Partner toevoegen</strong>
                        </Text>
                    </>
                ),
            },
            image: "2.png",
        },
        {
            index: 3,
            title: {
                English: "Assign Permissions",
                Spanish: "Asignar permisos",
                Dutch: "Machtigingen toewijzen",
            },
            description: {
                English: ({ accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Select the Ad account you'd like to grant access to from the dropdown.
                            Then, under permissions, select <strong>{accessLevel}</strong>
                        </Text>
                        <br />
                        <Text fz="sm">
                            Then click <strong>Assign Permissions</strong> and you're done!
                        </Text>
                    </>
                ),
                Spanish: ({ accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Selecciona la cuenta publicitaria a la que deseas otorgar acceso del
                            menú desplegable. Luego, bajo permisos, selecciona{" "}
                            <strong>{accessLevel}</strong>
                        </Text>
                        <br />
                        <Text fz="sm">
                            Luego haz clic en <strong>Asignar permisos</strong> ¡y has terminado!
                        </Text>
                    </>
                ),
                Dutch: ({ accessLevel }) => (
                    <>
                        <Text fz="sm">
                            Selecteer het advertentieaccount waartoe u toegang wilt verlenen uit
                            de dropdown. Selecteer vervolgens onder machtigingen{" "}
                            <strong>{accessLevel}</strong>
                        </Text>
                        <br />
                        <Text fz="sm">
                            Klik vervolgens op <strong>Machtigingen toewijzen</strong> en je bent
                            klaar!
                        </Text>
                    </>
                ),
            },
        },
    ],






    "ProfitMetrics Partner": [
        {
            index: 0,
            title: {
                English: "Send an email to ProfitMetrics support",
                Spanish: "Enviar un correo electrónico al soporte de ProfitMetrics",
                Dutch: "Stuur een e-mail naar ProfitMetrics ondersteuning",
            },
            description: {
                English: ({ inviteIdentifier }) => {
                    const [domain, setDomain] = useState("");
                    const [name, setName] = useState("");
                    const [companyName, setCompanyName] = useState("");
                    const contentRef = useRef();
                    return (
                        <Box>
                            <Text fz="sm">
                                To grant access, you must send a request to the ProfitMetrics team.
                            </Text>
                            <Text fz="sm">Use this email template to request access:</Text>
                            <Paper mt={5} withBorder shadow="sm" p="sm" radius={10}>
                                <Text fz="sm">
                                    <strong>To:</strong> support@profitmetrics.io
                                </Text>
                                <Text fz="sm">
                                    <strong>Subject:</strong> Can you give my agency access
                                </Text>
                                {/* @ts-ignore */}
                                <Text ref={contentRef} mt={10} lh={1.2} fz="sm">
                                    Dear ProfitMetrics Support,
                                    <br />
                                    <br />
                                    I would like you to grant access to my ProfitMetrics account for
                                    the domain <InlineInput value={domain} setValue={setDomain} placeholder="your domain" />
                                    .
                                    <br />
                                    <br />
                                    The agency is {inviteIdentifier}.
                                    <br />
                                    Please let me know once access has been granted.
                                    <br />
                                    <br />
                                    Best regards,
                                    <br />
                                    <InlineInput value={name} setValue={setName} placeholder="Full Name" />,{" "}
                                    <InlineInput value={companyName} setValue={setCompanyName} placeholder="Company Name" />
                                </Text>
                            </Paper>
                            <CopyButton value={contentRef.current?.innerText}>
                                {({ copied, copy }) => (
                                    <Button
                                        onClick={copy}
                                        bg={copied ? "green.1" : undefined}
                                        variant="default"
                                        mt={7}
                                        leftSection={copied ? <Check size={20} /> : <Copy size={20} />}
                                        fullWidth
                                    >
                                        {copied ? "Copied" : "Copy to Clipboard"}
                                    </Button>
                                )}
                            </CopyButton>
                            <Divider label="then" my={3} mb={5} />
                            <Box className="flex aic" style={{ gap: 10 }}>
                                <Button
                                    onClick={() => window.open(`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@profitmetrics.io&su=${encodeURIComponent("Can you give my agency access")}&body=${encodeURIComponent(contentRef.current.innerText)}`)}
                                    variant="default"
                                    style={{ flex: 1 }}
                                    leftSection={<Image src={`/images/logos/gmail.webp`} width={20} height={20} />}
                                >
                                    Open Gmail
                                </Button>
                                <Button
                                    onClick={() => window.open(`https://outlook.live.com/mail/0/`)}
                                    variant="default"
                                    style={{ flex: 1 }}
                                    leftSection={<Image src={`/images/logos/outlook.webp`} width={20} height={20} />}
                                >
                                    Open Outlook
                                </Button>
                            </Box>
                        </Box>
                    );
                },
                Spanish: ({ inviteIdentifier }) => {
                    const [domain, setDomain] = useState("");
                    const [name, setName] = useState("");
                    const [companyName, setCompanyName] = useState("");
                    const contentRef = useRef();
                    return (
                        <Box>
                            <Text fz="sm">
                                Para otorgar acceso, debe enviar una solicitud al equipo de ProfitMetrics.
                            </Text>
                            <Text fz="sm">Utiliza esta plantilla de correo electrónico para solicitar acceso:</Text>
                            <Paper mt={5} withBorder shadow="sm" p="sm" radius={10}>
                                <Text fz="sm">
                                    <strong>Para:</strong> support@profitmetrics.io
                                </Text>
                                <Text fz="sm">
                                    <strong>Asunto:</strong> ¿Puede dar acceso a mi agencia?
                                </Text>
                                {/* @ts-ignore */}
                                <Text ref={contentRef} mt={10} lh={1.2} fz="sm">
                                    Estimado Soporte de ProfitMetrics,
                                    <br />
                                    <br />
                                    Me gustaría que otorgara acceso a mi cuenta de ProfitMetrics para
                                    el dominio <InlineInput value={domain} setValue={setDomain} placeholder="tu dominio" />.
                                    <br />
                                    <br />
                                    La agencia es {inviteIdentifier}.
                                    <br />
                                    Por favor, hágamelo saber una vez que se haya otorgado el acceso.
                                    <br />
                                    <br />
                                    Atentamente,
                                    <br />
                                    <InlineInput value={name} setValue={setName} placeholder="Nombre Completo" />,{" "}
                                    <InlineInput value={companyName} setValue={setCompanyName} placeholder="Nombre de la Empresa" />
                                </Text>
                            </Paper>
                            <CopyButton value={contentRef.current?.innerText}>
                                {({ copied, copy }) => (
                                    <Button
                                        onClick={copy}
                                        bg={copied ? "green.1" : undefined}
                                        variant="default"
                                        mt={7}
                                        leftSection={copied ? <Check size={20} /> : <Copy size={20} />}
                                        fullWidth
                                    >
                                        {copied ? "Copiado" : "Copiar al Portapapeles"}
                                    </Button>
                                )}
                            </CopyButton>
                            <Divider label="entonces" my={3} mb={5} />
                            <Box className="flex aic" style={{ gap: 10 }}>
                                <Button
                                    onClick={() => window.open(`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@profitmetrics.io&su=${encodeURIComponent("¿Puede dar acceso a mi agencia?")}&body=${encodeURIComponent(contentRef.current.innerText)}`)}
                                    variant="default"
                                    style={{ flex: 1 }}
                                    leftSection={<Image src={`/images/logos/gmail.webp`} width={20} height={20} />}
                                >
                                    Abrir Gmail
                                </Button>
                                <Button
                                    onClick={() => window.open(`https://outlook.live.com/mail/0/`)}
                                    variant="default"
                                    style={{ flex: 1 }}
                                    leftSection={<Image src={`/images/logos/outlook.webp`} width={20} height={20} />}
                                >
                                    Abrir Outlook
                                </Button>
                            </Box>
                        </Box>
                    );
                },
                Dutch: ({ inviteIdentifier }) => {
                    const [domain, setDomain] = useState("");
                    const [name, setName] = useState("");
                    const [companyName, setCompanyName] = useState("");
                    const contentRef = useRef();
                    return (
                        <Box>
                            <Text fz="sm">
                                Om toegang te verlenen, moet je een verzoek sturen naar het ProfitMetrics-team.
                            </Text>
                            <Text fz="sm">Gebruik deze e-mailsjabloon om toegang te vragen:</Text>
                            <Paper mt={5} withBorder shadow="sm" p="sm" radius={10}>
                                <Text fz="sm">
                                    <strong>Aan:</strong> support@profitmetrics.io
                                </Text>
                                <Text fz="sm">
                                    <strong>Onderwerp:</strong> Kunt u mijn bureau toegang verlenen?
                                </Text>
                                {/* @ts-ignore */}
                                <Text ref={contentRef} mt={10} lh={1.2} fz="sm">
                                    Geachte ProfitMetrics Ondersteuning,
                                    <br />
                                    <br />
                                    Ik zou graag willen dat u toegang verleent tot mijn ProfitMetrics-account voor
                                    het domein <InlineInput value={domain} setValue={setDomain} placeholder="jouw domein" />.
                                    <br />
                                    <br />
                                    Het bureau is {inviteIdentifier}.
                                    <br />
                                    Laat het me weten zodra er toegang is verleend.
                                    <br />
                                    <br />
                                    Met vriendelijke groet,
                                    <br />
                                    <InlineInput value={name} setValue={setName} placeholder="Volledige Naam" />,{" "}
                                    <InlineInput value={companyName} setValue={setCompanyName} placeholder="Bedrijfsnaam" />
                                </Text>
                            </Paper>
                            <CopyButton value={contentRef.current?.innerText}>
                                {({ copied, copy }) => (
                                    <Button
                                        onClick={copy}
                                        bg={copied ? "green.1" : undefined}
                                        variant="default"
                                        mt={7}
                                        leftSection={copied ? <Check size={20} /> : <Copy size={20} />}
                                        fullWidth
                                    >
                                        {copied ? "Gekopieerd" : "Kopieren naar klembord"}
                                    </Button>
                                )}
                            </CopyButton>
                            <Divider label="vervolgens" my={3} mb={5} />
                            <Box className="flex aic" style={{ gap: 10 }}>
                                <Button
                                    onClick={() => window.open(`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@profitmetrics.io&su=${encodeURIComponent("Kunt u mijn bureau toegang verlenen?")}&body=${encodeURIComponent(contentRef.current.innerText)}`)}
                                    variant="default"
                                    style={{ flex: 1 }}
                                    leftSection={<Image src={`/images/logos/gmail.webp`} width={20} height={20} />}
                                >
                                    Open Gmail
                                </Button>
                                <Button
                                    onClick={() => window.open(`https://outlook.live.com/mail/0/`)}
                                    variant="default"
                                    style={{ flex: 1 }}
                                    leftSection={<Image src={`/images/logos/outlook.webp`} width={20} height={20} />}
                                >
                                    Open Outlook
                                </Button>
                            </Box>
                        </Box>
                    );
                },
            },
        },
    ],




    "LinkedIn Company Page": [
        {
            "index": 0,
            "title": {
                "English": "Navigate to company page",
                "Dutch": "Navigeer naar bedrijfspagina",
                "Spanish": "Navegar a la página de la empresa"
            },
            "description": {
                "English": () => (
                    <Text fz="sm">
                        Go to your <InlinePageLink href="https://www.linkedin.com/feed/" newTab>LinkedIn feed</InlinePageLink>,
                        select your company's LinkedIn settings page from the sidebar, then click <strong>Settings</strong> in the left sidebar.
                    </Text>
                ),
                "Dutch": () => (
                    <Text fz="sm">
                        Ga naar je <InlinePageLink href="https://www.linkedin.com/feed/" newTab>LinkedIn-feed</InlinePageLink>,
                        selecteer de LinkedIn-instellingenpagina van je bedrijf via de zijbalk en klik vervolgens op <strong>Instellingen</strong> in de linkerzijbalk.
                    </Text>
                ),
                "Spanish": () => (
                    <Text fz="sm">
                        Ve a tu <InlinePageLink href="https://www.linkedin.com/feed/" newTab>feed de LinkedIn</InlinePageLink>,
                        selecciona la página de configuración de LinkedIn de tu empresa desde la barra lateral y luego haz clic en <strong>Configuración</strong> en la barra lateral izquierda.
                    </Text>
                )
            },
            "image": "1.gif"
        },
        {
            "index": 1,
            "title": {
                "English": "Assign Role",
                "Dutch": "Rol toewijzen",
                "Spanish": "Asignar rol"
            },
            "description": {
                "English": ({ inviteIdentifier, accessLevel, resource }) => (
                    <>
                        <Text fz="sm">Click on <strong>Manage Admins</strong>, then <strong>Add Admin</strong>. Search for and add the following users:</Text>
                        <Box mt={7} mb={5} className="flex fdc" style={{ gap: 5 }}>
                            {(inviteIdentifier as string[]).map((username, i) => (
                                <Box className="flex aic">
                                    <Avatar mr={8} size={22}>{username[0]}</Avatar>
                                    <Tooltip label="View LinkedIn profile">
                                        <Text lh={1} component={Link} href={resource[i] || ""} target="_blank" style={{ borderBottom: "1px dashed var(--mantine-color-blue-6)" }} c="blue" fz="sm" fw={500}>{username}</Text>
                                    </Tooltip>
                                    <Text c="dimmed" fw={400} ml={5} fz="sm"> - {accessLevel}</Text>
                                </Box>
                            ))}
                        </Box>
                    </>
                ),
                "Dutch": ({ inviteIdentifier, accessLevel, resource }) => (
                    <>
                        <Text fz="sm">Klik op <strong>Beheer beheerders</strong> en vervolgens op <strong>Beheerder toevoegen</strong>. Zoek naar en voeg de volgende gebruikers toe:</Text>
                        <Box mt={7} mb={5} className="flex fdc" style={{ gap: 5 }}>
                            {(inviteIdentifier as string[]).map((username, i) => (
                                <Box className="flex aic">
                                    <Avatar mr={8} size={22}>{username[0]}</Avatar>
                                    <Tooltip label="Bekijk LinkedIn-profiel">
                                        <Text lh={1} component={Link} href={resource[i] || ""} target="_blank" style={{ borderBottom: "1px dashed var(--mantine-color-blue-6)" }} c="blue" fz="sm" fw={500}>{username}</Text>
                                    </Tooltip>
                                    <Text c="dimmed" fw={400} ml={5} fz="sm"> - {accessLevel}</Text>
                                </Box>
                            ))}
                        </Box>
                    </>
                ),
                "Spanish": ({ inviteIdentifier, accessLevel, resource }) => (
                    <>
                        <Text fz="sm">Haz clic en <strong>Gestionar administradores</strong> y luego en <strong>Añadir administrador</strong>. Busca y añade a los siguientes usuarios:</Text>
                        <Box mt={7} mb={5} className="flex fdc" style={{ gap: 5 }}>
                            {(inviteIdentifier as string[]).map((username, i) => (
                                <Box className="flex aic">
                                    <Avatar mr={8} size={22}>{username[0]}</Avatar>
                                    <Tooltip label="Ver perfil de LinkedIn">
                                        <Text lh={1} component={Link} href={resource[i] || ""} target="_blank" style={{ borderBottom: "1px dashed var(--mantine-color-blue-6)" }} c="blue" fz="sm" fw={500}>{username}</Text>
                                    </Tooltip>
                                    <Text c="dimmed" fw={400} ml={5} fz="sm"> - {accessLevel}</Text>
                                </Box>
                            ))}
                        </Box>
                    </>
                )
            },
            "image": "2.gif"
        }
    ],

    "LinkedIn Ads": [
        {
            "index": 0,
            "title": {
                "English": "Navigate to Campaign Manager",
                "Dutch": "Ga naar Campaign Manager",
                "Spanish": "Navega a Campaign Manager"
            },
            "description": {
                "English": () => (
                    <Text fz="sm">Go to <InlinePageLink newTab href="https://www.linkedin.com/campaignmanager/">LinkedIn Campaign Manager</InlinePageLink>, and navigate to <strong>Account Settings {">"} Manage Access</strong> in the sidebar.</Text>
                ),
                "Dutch": () => (
                    <Text fz="sm">Ga naar <InlinePageLink newTab href="https://www.linkedin.com/campaignmanager/">LinkedIn Campaign Manager</InlinePageLink> en navigeer naar <strong>Accountinstellingen {">"} Toegang beheren</strong> in de zijbalk.</Text>
                ),
                "Spanish": () => (
                    <Text fz="sm">Ve a <InlinePageLink newTab href="https://www.linkedin.com/campaignmanager/">LinkedIn Campaign Manager</InlinePageLink> y navega a <strong>Configuración de la cuenta {">"} Administrar acceso</strong> en la barra lateral.</Text>
                )
            },
            "image": "1.gif"
        },
        {
            "index": 1,
            "title": {
                "English": "Add a User",
                "Dutch": "Gebruiker toevoegen",
                "Spanish": "Añadir un usuario"
            },
            "description": {
                "English": ({ accessLevel, inviteIdentifier, resource }) => (
                    <>
                        <Text fz="sm">Click on <strong>Add User</strong>, and add the following users:</Text>
                        <Box mt={5} className="flex fdc" style={{ gap: 5 }}>
                            {(inviteIdentifier as string[]).map((username, i) => (
                                <Box className="flex aic">
                                    <Avatar mr={8} size={22}>{username[0]}</Avatar>
                                    <Tooltip label="View LinkedIn profile">
                                        <Text lh={1} component={Link} href={resource[i] || ""} target="_blank" style={{ borderBottom: "1px dashed var(--mantine-color-blue-6)" }} c="blue" fz="sm" fw={500}>{username}</Text>
                                    </Tooltip>
                                    <Text c="dimmed" fw={400} ml={5} fz="sm"> - {accessLevel}</Text>
                                </Box>
                            ))}
                        </Box>
                        <Divider opacity={.5} my={10} />
                        <Box>
                            <Box w="fit-content" className="flex aic" style={{ gap: 5 }}>
                                <InfoSquare size={13} />
                                <Text lh={1} fz="sm" fw={500} style={{ borderBottom: "1px dashed #000" }}>Important</Text>
                            </Box>
                            <Box mt={8} style={{ borderLeft: "2px solid rgba(0,0,0,.2)", borderRadius: 0 }} pl={10}>
                                <Text fz="xs">You can only invite 1st and 2nd connections to your LinkedIn Ads account. If the correct name doesn't show up, please send {inviteIdentifier?.length > 1 ? "connection requests" : "a connection request"} to {(inviteIdentifier as string[]).map((name, i) => <span><InlinePageLink href={resource[i] || ""} newTab>{name}</InlinePageLink>, </span>)} and try again.</Text>
                            </Box>
                        </Box>
                    </>
                ),
                "Dutch": ({ accessLevel, inviteIdentifier, resource }) => (
                    <>
                        <Text fz="sm">Klik op <strong>Gebruiker toevoegen</strong> en voeg de volgende gebruikers toe:</Text>
                        <Box mt={5} className="flex fdc" style={{ gap: 5 }}>
                            {(inviteIdentifier as string[]).map((username, i) => (
                                <Box className="flex aic">
                                    <Avatar mr={8} size={22}>{username[0]}</Avatar>
                                    <Tooltip label="LinkedIn-profiel bekijken">
                                        <Text lh={1} component={Link} href={resource[i] || ""} target="_blank" style={{ borderBottom: "1px dashed var(--mantine-color-blue-6)" }} c="blue" fz="sm" fw={500}>{username}</Text>
                                    </Tooltip>
                                    <Text c="dimmed" fw={400} ml={5} fz="sm"> - {accessLevel}</Text>
                                </Box>
                            ))}
                        </Box>
                        <Divider opacity={.5} my={10} />
                        <Box>
                            <Box w="fit-content" className="flex aic" style={{ gap: 5 }}>
                                <InfoSquare size={13} />
                                <Text lh={1} fz="sm" fw={500} style={{ borderBottom: "1px dashed #000" }}>Belangrijk</Text>
                            </Box>
                            <Box mt={8} style={{ borderLeft: "2px solid rgba(0,0,0,.2)", borderRadius: 0 }} pl={10}>
                                <Text fz="xs">Je kunt alleen 1e- en 2e-graads connecties uitnodigen voor je LinkedIn Ads-account. Als de juiste naam niet verschijnt, stuur dan {inviteIdentifier?.length > 1 ? "connectieverzoeken" : "een connectieverzoek"} naar {(inviteIdentifier as string[]).map((name, i) => <span><InlinePageLink href={resource[i] || ""} newTab>{name}</InlinePageLink>, </span>)} en probeer het opnieuw.</Text>
                            </Box>
                        </Box>
                    </>
                ),
                "Spanish": ({ accessLevel, inviteIdentifier, resource }) => (
                    <>
                        <Text fz="sm">Haz clic en <strong>Añadir usuario</strong> e invita a los siguientes usuarios:</Text>
                        <Box mt={5} className="flex fdc" style={{ gap: 5 }}>
                            {(inviteIdentifier as string[]).map((username, i) => (
                                <Box className="flex aic">
                                    <Avatar mr={8} size={22}>{username[0]}</Avatar>
                                    <Tooltip label="Ver perfil de LinkedIn">
                                        <Text lh={1} component={Link} href={resource[i] || ""} target="_blank" style={{ borderBottom: "1px dashed var(--mantine-color-blue-6)" }} c="blue" fz="sm" fw={500}>{username}</Text>
                                    </Tooltip>
                                    <Text c="dimmed" fw={400} ml={5} fz="sm"> - {accessLevel}</Text>
                                </Box>
                            ))}
                        </Box>
                        <Divider opacity={.5} my={10} />
                        <Box>
                            <Box w="fit-content" className="flex aic" style={{ gap: 5 }}>
                                <InfoSquare size={13} />
                                <Text lh={1} fz="sm" fw={500} style={{ borderBottom: "1px dashed #000" }}>Importante</Text>
                            </Box>
                            <Box mt={8} style={{ borderLeft: "2px solid rgba(0,0,0,.2)", borderRadius: 0 }} pl={10}>
                                <Text fz="xs">Solo puedes invitar a conexiones de primer y segundo grado a tu cuenta de LinkedIn Ads. Si no aparece el nombre correcto, envía {inviteIdentifier?.length > 1 ? "solicitudes de conexión" : "una solicitud de conexión"} a {(inviteIdentifier as string[]).map((name, i) => <span><InlinePageLink href={resource[i] || ""} newTab>{name}</InlinePageLink>, </span>)} y vuelve a intentarlo.</Text>
                            </Box>
                        </Box>
                    </>
                )
            },
            "image": "2.gif"
        }
    ],
    "Klaviyo Account": [
        {
            index: 0,
            title: {
                English: "Navigate to Account Settings",
                Spanish: "Ve a la configuración de la cuenta",
                Dutch: "Navigeer naar accountinstellingen",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        Sign into your <InlinePageLink href="https://www.klaviyo.com/login" newTab>Klaviyo Dashboard</InlinePageLink>. In the bottom left corner, click on <strong>Account</strong>, then click on the <strong>Settings</strong> button.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        Inicia sesión en tu <InlinePageLink href="https://www.klaviyo.com/login" newTab>panel de control de Klaviyo</InlinePageLink>. En la esquina inferior izquierda, haz clic en <strong>Cuenta</strong> y luego en el botón de <strong>Configuración</strong>.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Meld je aan bij je <InlinePageLink href="https://www.klaviyo.com/login" newTab>Klaviyo-dashboard</InlinePageLink>. Klik linksonder op <strong>Account</strong> en klik vervolgens op de knop <strong>Instellingen</strong>.
                    </Text>
                ),
            },
            image: "1.gif"
        },
        {
            index: 1,
            title: {
                English: "Manage Users",
                Spanish: "Administrar usuarios",
                Dutch: "Gebruikers beheren",
            },
            description: {
                English: () => (
                    <Text fz="sm">
                        In the menu, click on <strong>Users</strong>, then click on <strong>Add New User</strong>.
                    </Text>
                ),
                Spanish: () => (
                    <Text fz="sm">
                        En el menú, haz clic en <strong>Usuarios</strong> y luego en <strong>Agregar nuevos usuarios</strong>.
                    </Text>
                ),
                Dutch: () => (
                    <Text fz="sm">
                        Klik in het menu op <strong>Gebruikers</strong> en vervolgens op <strong>Nieuwe gebruikers toevoegen</strong>.
                    </Text>
                ),
            },
            image: "2.gif"
        },
        {
            index: 2,
            title: {
                English: "Invite User",
                Spanish: "Invitar usuario",
                Dutch: "Gebruiker uitnodigen",
            },
            description: {
                English: ({ accessLevel, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Click on <strong>Invite User</strong>, enter <strong>{inviteIdentifier}</strong>, and assign the <strong>{accessLevel}</strong> role.
                        </Text>
                    </>
                ),
                Spanish: ({ accessLevel, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Haz clic en <strong>Invitar usuario</strong>, ingresa el correo electrónico <strong>{inviteIdentifier}</strong>, y asigna el rol <strong>{accessLevel}</strong>.
                        </Text>
                    </>
                ),
                Dutch: ({ accessLevel, inviteIdentifier }) => (
                    <>
                        <Text fz="sm">
                            Klik op <strong>Gebruiker uitnodigen</strong>, voer <strong>{inviteIdentifier}</strong> in, en wijs de rol <strong>{accessLevel}</strong> toe.
                        </Text>
                    </>
                ),
            },
            image: "3.gif"
        },
    ]
} as Partial<Record<AnyService, Array<Instruction>>>