import { GrantsStatus } from "@/components/invite/GrantsContainer"
import { AccountTypes, AnyService } from "../../../../types/global"
import api from "../api"
import { notifications } from "@mantine/notifications"

export const markServiceGranted = async (inviteID: string, platform: AccountTypes, service: AnyService, updateGrantsStatus?: (newGrants: GrantsStatus) => void, status?: boolean | "skipped") => new Promise((res, rej) => {
    api.post("/invite/public/manual/status", {
        inviteID: inviteID,
        service: service,
        account: platform,
        status
    }).then((response) => {
        if (response.error) {
            notifications.show({
                color: "red",
                title: "Oops!",
                message: response.msg
            })
            return rej()
        }
        if (updateGrantsStatus) {
            updateGrantsStatus({
                [service]: {
                    granted: true
                }

            })
        }
        res(undefined)
    })
})