import { Loader, Transition, useMantineColorScheme, useMantineTheme } from "@mantine/core"
import { useEffect, useState } from "react"
import { Logo } from "./Logo"

export const LoadingScreen = () => {
    const [trans, setTrans] = useState(false)
    const { colorScheme } = useMantineColorScheme()
    useEffect(() => {
        setTrans(true)
    }, [])
    return (
        <Transition mounted={trans} transition="pop">
            {(styles) => (
                <div id="#loading_screen" className='flex aic jcc fdc' style={{ width: "100vw", height: "100vh", gap: 15, ...styles }}>
                    <Logo dark={colorScheme === "dark"} width={300} />
                    <Loader size={"sm"} />
                </div>
            )}
        </Transition>
    )
}