import React, { useEffect, useState } from 'react'
import { RequestedAccounts } from '../../../../types/global'
import api from '@/utils/api'
import { Box, Loader, Text } from '@mantine/core'

export default function InviteEmailPreview({ requestedAccounts, clientID, intakeForm }: {
    requestedAccounts: RequestedAccounts, clientID: string, intakeForm: boolean
}) {
    const [html, setHTML] = useState<string | undefined>(undefined)

    useEffect(() => {
        api.post<string>("/invite/preview/email/template", {
            requestedAccounts, clientID, intakeForm
        }).then((res) => {
            setHTML(res.data)
        })
    }, [])

    return (
        <>
            {html
                ? <div style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }} dangerouslySetInnerHTML={{ __html: html }} />
                : <Box h="100%" py={60} className='flex aic jcc fdc'>
                    <Loader size={"sm"} />
                    <Text fw={500} mt={10} fz="sm">Loading email preview...</Text>
                </Box>
            }

        </>

    )
}
